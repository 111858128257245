import { useState, useContext, createContext } from "react";

const GlobalContext = createContext({});

const GlobalStateContext = ({ children }) => {
  const [currency, setCurrency] = useState("bnb");

  return (
    <GlobalContext.Provider
      value={{
        currency,
        setCurrency,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

const useGlobalStateContext = () => useContext(GlobalContext);
export { GlobalStateContext, useGlobalStateContext };
