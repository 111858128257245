import styles from "./OnboardingOfferModal.module.sass";
import classNames from "classnames";
import Button from "../../Button";
import { useTranslation } from "react-i18next";

const OnboardingOfferModal = ({
  setIsPopupOpen,
  stage = 0,
  onboardingDriver,
}) => {
  const { t } = useTranslation();

  const onboardingStatusExpiration = new Date();
  onboardingStatusExpiration.setDate(
    onboardingStatusExpiration.getDate() + 400
  ); // set max expiration date for seve onboarding info to cookies

  const stagesList = t("onboardingStagesList", { returnObjects: true });

  const onboardingDriveHandler = () => {
    setIsPopupOpen(false);
    onboardingDriver.drive();

    setTimeout(() => {
      onboardingDriver.refresh();
    }, 500);
  };

  return (
    <div className={styles.popupWrapper}>
      <div
        className={classNames(
          styles.popup,
          styles.onboardingNotifications,
          "fade-show"
        )}
      >
        <div className={styles.inner}>
          <div
            className={styles.closeBtn}
            onClick={() => setIsPopupOpen(false)}
          >
            <svg
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1.5L5 5.5M9 9.5L5 5.5M5 5.5L9 1.5L1 9.5"
                stroke="#252526"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          {stagesList[stage].title && (
            <div className={styles.title}>{stagesList[stage].title}</div>
          )}
          {stagesList[stage].content && (
            <div className={styles.content}>{stagesList[stage].content}</div>
          )}
          <div className={styles.footer}>
            {stage !== stagesList.length - 1 ? (
              <Button
                className={styles.onboardingNotifications__button}
                onClick={onboardingDriveHandler}
                text={t("onboardingModal.buttonStart")}
              />
            ) : (
              <Button
                className={classNames(
                  styles.onboardingNotifications__button,
                  styles.close
                )}
                onClick={() => setIsPopupOpen(false)}
                text={t("onboardingModal.buttonСomplete")}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingOfferModal;
