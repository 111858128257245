import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { socialLinks } from "../../config/constants";
import {
  bscscan,
  facebookIco,
  instagramIco,
  twitterIco,
  youtubeIco,
  telegramIco,
} from "../../assets/icons";
import styles from "./SocialsList.module.sass";

const SocialsList = ({ mod }) => {
  const { t } = useTranslation();

  const socialBar = {
    // bscscan: {
    //   url:
    //     typeof t("socialNetworks.bscscan", { returnObjects: true }) === "object"
    //       ? t("socialNetworks.bscscan", { returnObjects: true }).link
    //       : socialLinks.bscscan,
    //   ico: bscscan,
    // },
    facebook: {
      url:
        typeof t("socialNetworks.facebook", { returnObjects: true }) ===
        "object"
          ? t("socialNetworks.facebook", { returnObjects: true }).link
          : socialLinks.facebook,
      ico: facebookIco,
    },
    instagram: {
      url:
        typeof t("socialNetworks.instagram", { returnObjects: true }) ===
        "object"
          ? t("socialNetworks.instagram", { returnObjects: true }).link
          : socialLinks.instagram,
      ico: instagramIco,
    },
    twitter: {
      url:
        typeof t("socialNetworks.twitter", { returnObjects: true }) === "object"
          ? t("socialNetworks.twitter", { returnObjects: true }).link
          : socialLinks.twitter,
      ico: twitterIco,
    },
    youtube: {
      url:
        typeof t("socialNetworks.youtube", { returnObjects: true }) === "object"
          ? t("socialNetworks.youtube", { returnObjects: true }).link
          : socialLinks.youtube,
      ico: youtubeIco,
    },
    telegram: {
      url:
        typeof t("socialNetworks.telegram", { returnObjects: true }) ===
        "object"
          ? t("socialNetworks.telegram", { returnObjects: true }).link
          : socialLinks.telegram,
      ico: telegramIco,
    },
  };

  return (
    <div
      className={classNames(
        styles.socialsList,
        mod && styles[`socialsList${mod}`]
      )}
    >
      {Object.keys(socialBar).map((item, i) => {
        return (
          <div key={i} className={styles.item}>
            <a
              href={socialBar[item].url}
              className={classNames(styles.link, styles[item])}
              target="_blank"
            >
              <img
                className={styles.icon}
                src={socialBar[item].ico}
                alt={`${item}-icon`}
                loading="lazy"
                width={14}
                height={14}
              />
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default SocialsList;
