import { envelopeIcon } from "../../assets/icons";
import { corpEmail } from "../../config/constants";
import { useTranslation } from "react-i18next";
import SocialsList from "../../components/SocialsList";
import styles from "./ContuctUs.module.sass";

const ContuctUs = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.contuctUs}>
      <div className="container">
        <div className={styles.inner}>
          <div className={styles.content}>
            <h2 className={styles.title}>{t("contactUs.title")}</h2>
            <div className={styles.description}>{t("contactUs.description")}</div>
          </div>
          <div className={styles.aside}>
            <ul className={styles.list}>
              <li className={styles.listItem}>
                <div className={styles.listTitle}>
                  <img
                    className={styles.listIcon}
                    src={envelopeIcon}
                    alt=""
                    loading="lazy"
                    width={30}
                    height={30}
                  />
                  {t("contactUs.emailLabel")}
                </div>
                <a className={styles.listLink} href={`mailto:${corpEmail}`}>
                  {corpEmail}
                </a>
              </li>
            </ul>
            <div className={styles.socials}>
              <SocialsList mod="Yellow" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContuctUs;
