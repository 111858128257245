import {
  useState,
  useEffect,
  useMemo,
  useContext,
  createContext,
  useCallback,
} from "react";
import {
  readContract,
  prepareWriteContract,
  writeContract,
  waitForTransaction,
  fetchBalance,
} from "@wagmi/core";
import { useAccount, useConnect } from "wagmi";
import { toast } from "react-hot-toast";
import abi from "../config/abi";
import { parseEther, formatEther } from "ethers";
import { bnbRateRequest, bugReport } from "../config/requests/requests";
import { tokenAddress } from "../config/constants";
import { getErrorNumber } from "../tools/common";
import { useTranslation } from "react-i18next";
import { GASFEE } from "../config/constants";

const Web3Context = createContext({});

const WalletConnectContext = ({ children }) => {
  const { t } = useTranslation();
  const account = useAccount();
  const [bnbRate, setBnbRate] = useState(null);
  const [balance, setBalance] = useState(0);
  const [investmentAmount, setInvestmentAmount] = useState(0);
  const [interestRate, setInterestRate] = useState("");
  const [isDeposited, setIsDeposited] = useState(false);
  const [partnerAddress, setPartnerAddress] = useState(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [lastInvestTimestamp, setLastInvestTimestamp] = useState(null);
  const [address, setAddress] = useState(null);
  const [rewards, setRewards] = useState(0);
  const [minDepositValue, setMinDepositValue] = useState(0);
  const [minWithdrawValue, setMinWithdrawValue] = useState(0);
  const [referralUsers, setReferralUsers] = useState({});
  const [isLeave, setIsLeave] = useState(false);
  const [isContractInfoLoaded, setIsContractInfoLoaded] = useState(false);

  const { reset, connect } = useConnect({
    chainId: 56,
  });

  // const { address, isConnected, connector, isConnecting, isDisconnected, isReconnecting, status } = useAccount()

  useMemo(() => bnbRateRequest().then((out) => setBnbRate(out[0])), []);

  const update = useCallback(() => {
    setShouldUpdate((prevState) => !prevState);
  }, [setShouldUpdate]);

  async function getBalance(address) {
    try {
      if (!address) return 0;

      const balance = await fetchBalance({ address });
      return balance;
    } catch (error) {
      console.error("Error fetching balance:", error);
      return 0;
    }
  }

  const getContract = async (functionName, address) => {
    try {
      if (address === undefined) {
        const contract = await readContract({
          address: tokenAddress,
          abi,
          functionName,
        });
        return contract;
      } else {
        if (address?.length > 0) {
          const contract = await readContract({
            address: tokenAddress,
            abi,
            functionName,
            args: [address],
          });
          return contract;
        }
        return null;
      }
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    }
  };

  const walletBalance = useMemo(
    async () => getBalance(address),
    [address, shouldUpdate]
  );

  const readStake = useMemo(
    async () => getContract("stake", address),
    [address, shouldUpdate]
  );

  const readIsLeft = useMemo(
    async () => getContract("left", address),
    [address, shouldUpdate]
  );

  const readPendingReward = useMemo(
    async () => getContract("getPendingReward", address),
    [address, shouldUpdate]
  );

  const readMinWithdrawValue = useMemo(
    async () => getContract("minWithdrawValue"),
    [address]
  );

  const readMinDepositValue = useMemo(
    async () => getContract("minDepositValue"),
    [address]
  );

  // const readReferralUsersLength = useMemo(
  //   async () => getContract("getReferralUsersLength", address),
  //   [address, shouldUpdate]
  // );

  const readReferralUsersIndexed = useMemo(async () => {
    // const usersLength = await readReferralUsersLength;
    if (address) {
      let referralUsers = await readContract({
        address: tokenAddress,
        abi,
        functionName: "getReferralUsers",
        args: [address],
      });
      //   let referralUsers = await readContract({
      //     address: tokenAddress,
      //     abi,
      //     functionName: "getReferralUsersIndexed",
      //     args: [address, 0, usersLength],
      //   });
      // console.log("referralUsers from contract", referralUsers);

      // return contract
      for (let i = 0; i < referralUsers.length; i++) {
        await readContract({
          address: tokenAddress,
          abi,
          functionName: "referralUserRewards",
          args: [address, referralUsers[i].user],
        }).then(res => {
          referralUsers[i]["reward"] = res;
        }).catch(err => {
          console.log('referralUserRewards error: ', err)
        });
      }
      return referralUsers;
    }
    return null;
  }, [address, shouldUpdate]);

  const makeDeposit = useCallback(
    async (
      amount,
      referralPartner = "0x8888888888888888888888888888888888888888",
      chainId = 56
    ) => {
      console.log('referralPartner', referralPartner)
      if (referralPartner.toLowerCase() === address.toLowerCase()) {
        toast.error("Cannot set your own address as partner");
        return;
      }
      try {
        // const network = getNetwork();
        // if (network.chain?.id !== 56) {
        //   await switchNetwork({ chainId: 56 });
        // }
        const config = await prepareWriteContract({
          address: tokenAddress,
          abi,
          functionName: "deposit",
          chainId,
          account: address,
          value: amount,
          gas: GASFEE,
          args: [referralPartner],
        });
        // calling an ethers Contract write method
        const { hash } = await writeContract(config);
        // waiting until transaction is processed (return promise)
        const data = waitForTransaction({ hash });

        toast.promise(data, {
          loading: t("contractNotification.transactionProgress"),
          success: () => {
            update();
            // GTAT event - Make Deposit
            if (typeof window !== "undefined" && address) {
              window.dataLayer.push({
                event: "make_deposit",
                amount: +formatEther(amount),
                exchange: bnbRate,
                wallet_address: `=${address}`,
              });
              console.log(
                "GA4 Event",
                `Make Deposit (${formatEther(
                  amount
                )} BNB) event from ${address} is sended successfuly!`,
                `Exchange BNB to USD is: ${bnbRate}`
              );
            }
            return (
              <p>{t("contractNotification.successDeposit", {amount: formatEther(amount)})}</p>
            );
          },
          error: (err) => `${err.message}`,
        });
      } catch (e) {
        console.log("Make deposit error: ", e.message);
        if (e.message.includes("User denied transaction signature")) {
          toast.error(
            `Make deposit: action declined #${getErrorNumber(e.message)}`
          );
          bugReport(
            `Error: ${e.message}; \n Amount: ${amount}; \n Address: ${address}`
          ).then(() => console.log("bug successfully sended"));
        } else {
          toast.error(
            `Make deposit: an error has occurred #${getErrorNumber(e.message)}`
          );
          bugReport(
            `Error: ${e.message}; \n Amount: ${amount}; \n Address: ${address}`
          ).then(() => console.log("bug successfully sended"));
        }
      }
    },
    [address]
  );

  const reinvest = useCallback(
    async (amount, chainId = 56) => {
      if (amount > 0) {
        try {
          // const network = getNetwork();
          // if (network.chain?.id !== 56) {
          //   await switchNetwork({ chainId: 56 });
          // }
          const amountWithDecimals = parseEther(amount.toString());
          // preparing a contract write
          const config = await prepareWriteContract({
            address: tokenAddress,
            abi,
            functionName: "reinvest",
            chainId,
            args: [amountWithDecimals],
            account: address,
          });
          // calling an ethers Contract write method
          const { hash } = await writeContract(config);
          // waiting until transaction is processed (return promise)
          const data = waitForTransaction({ hash });
          toast.promise(data, {
            loading: t("contractNotification.reinvestProgress"),
            success: () => {
              update();
              return (
                <p>
                  {t("contractNotification.successReinvest", {amount: parseFloat((+amount).toFixed(2))})}
                </p>
              );
            },
            error: (err) => `${err.message}`,
          });
        } catch (e) {
          console.log("Reinvest error: ", e.message);
          if (e.message.includes("User denied transaction signature")) {
            toast.error(
              `Reinvest: action declined #${getErrorNumber(e.message)}`
            );
            bugReport(
              `Error: ${e.message}; \n Amount: ${amount}; \n Address: ${address}`
            ).then(() => console.log("bug successfully sended"));
          } else {
            toast.error(
              `Reinvest: an error has occurred #${getErrorNumber(e.message)}`
            );
            bugReport(
              `Error: ${e.message}; \n Amount: ${amount}; \n Address: ${address}`
            ).then(() => console.log("bug successfully sended"));
            // toast.error(e.message);
          }
        }
      }
    },
    [address]
  );

  const withdraw = useCallback(
    async (amount, chainId = 56) => {
      if (amount >= formatEther(minWithdrawValue)) {
        try {
          // const network = getNetwork();
          // if (network.chain?.id !== 56) {
          //   await switchNetwork({ chainId: 56 });
          // }
          const amountWithDecimals = parseEther(amount.toString());
          // preparing a contract write
          const config = await prepareWriteContract({
            address: tokenAddress,
            abi,
            functionName: "withdraw",
            chainId,
            args: [amountWithDecimals],
            account: address,
          });
          // calling an ethers Contract write method
          const { hash } = await writeContract(config);
          // waiting until transaction is processed (return promise)
          const data = waitForTransaction({ hash });

          toast.promise(data, {
            loading: t("contractNotification.loadingWithdraw"),
            success: () => {
              update();
              return (
                <p>
                  {t("contractNotification.successWithdraw", {amount: parseFloat((+amount).toFixed(2))})}
                </p>
              );
            },
            error: (err) => `${err.message}`,
          });
        } catch (e) {
          console.log("Withdraw error: ", e.message);
          if (e.message.includes("User denied transaction signature")) {
            toast.error(
              `Withdraw: action declined #${getErrorNumber(e.message)}`
            );
            bugReport(
              `Error: ${e.message}; \n Amount: ${amount}; \n Address: ${address}`
            ).then((res) => console.log("bug successfully sended", res));
          } else {
            toast.error(
              `Withdraw: an error has occurred #${getErrorNumber(e.message)}`
            );
            bugReport(
              `Error: ${e.message}; \n Amount: ${amount}; \n Address: ${address}`
            ).then((res) => console.log("bug successfully sended", res));
          }
        }
      } else {
        toast.error(
          t("toasts.minAmount", {
            minAmount: `${formatEther(minWithdrawValue)} BNB`,
          })
        );
      }
    },
    [address, minWithdrawValue]
  );

  const withdrawReferral = useCallback(
    async (users, amounts, chainId = 56) => {
      try {
        // const network = getNetwork();
        // if (network.chain?.id !== 56) {
        //   await switchNetwork({ chainId: 56 });
        // }
        // preparing a contract write
        const config = await prepareWriteContract({
          address: tokenAddress,
          abi,
          functionName: "withdrawReferral",
          chainId,
          args: [users, amounts],
          // account: address,
        });
        // calling an ethers Contract write method
        const { hash } = await writeContract(config);
        // waiting until transaction is processed (return promise)
        const data = waitForTransaction({ hash });

        toast.promise(data, {
          loading: t("contractNotification.refferalWithdrawProgress"),
          success: () => {
            update();
            return (
              <p>{t("contractNotification.successRewardsReferralsWithdrawed")}</p>
            );
          },
          error: (err) => `${err.message}`,
        });
      } catch (e) {
        console.log("Withdraw Referral error: ", e);
        if (e.message.includes("User denied transaction signature")) {
          toast.error(
            `Refferal withdraw: action declined #${getErrorNumber(e.message)}`
          );
          bugReport(
            `Error: ${e.message}; \n Amount: ${amounts}; \n Address: ${address}`
          ).then(() => console.log("bug successfully sended"));
        } else {
          toast.error(
            `Refferal withdraw: an error has occurred #${getErrorNumber(
              e.message
            )}`
          );
          bugReport(
            `Error: ${e.message}; \n Amount: ${amounts}; \n Address: ${address}`
          ).then(() => console.log("bug successfully sended"));
        }
      }
    },
    [address]
  );

  const reinvestReferral = useCallback(
    async (users, amounts, chainId = 56) => {
      try {
        // const network = getNetwork();
        // if (network.chain?.id !== 56) {
        //   await switchNetwork({ chainId: 56 });
        // }
        // preparing a contract write
        const config = await prepareWriteContract({
          address: tokenAddress,
          abi,
          functionName: "reinvestReferral",
          chainId,
          args: [users, amounts],
          // account: address,
        });
        // calling an ethers Contract write method
        const { hash } = await writeContract(config);
        // waiting until transaction is processed (return promise)
        const data = waitForTransaction({ hash });

        toast.promise(data, {
          loading: t("contractNotification.loadingRefferalReinvest"),
          success: () => {
            update();
            return (
              <p>{t("contractNotification.successRewardsReferralsReinvested")}</p>
            );
          },
          error: (err) => `${err.message}`,
        });
      } catch (e) {
        console.log("Refferal reinvest error: ", e.message);
        if (e.message.includes("User denied transaction signature")) {
          toast.error(
            `Refferal reinvest: action declined #${getErrorNumber(e.message)}`
          );
          bugReport(
            `Error: ${e.message}; \n Amount: ${amounts}; \n Address: ${address}`
          ).then(() => console.log("bug successfully sended"));
        } else {
          toast.error(
            `Refferal reinvest: an error has occurred #${getErrorNumber(
              e.message
            )}`
          );
          bugReport(
            `Error: ${e.message}; \n Amount: ${amounts}; \n Address: ${address}`
          ).then(() => console.log("bug successfully sended"));
        }
      }
    },
    [address]
  );

  const loadData = useCallback(async () => {
    console.log("updating data from smart contract");
    try {
      const balance = await walletBalance;
      balance && setBalance(parseInt(balance.value));

      const [
        stake,
        notWithdrawn,
        timestamp,
        partner,
        interestRate,
        isDeposited,
      ] = (await readStake) || [];
      setLastInvestTimestamp(timestamp);
      stake && setInvestmentAmount(parseInt(stake));
      setInterestRate(interestRate);
      typeof isDeposited != "undefined" && setIsDeposited(isDeposited);
      setPartnerAddress(partner);
      const pendingReward = await readPendingReward;
      const minWithdrawValue = await readMinWithdrawValue;
      const minDepositValue = await readMinDepositValue;
      const isLeave = await readIsLeft;
      setMinWithdrawValue(minWithdrawValue);
      setMinDepositValue(minDepositValue);
      setIsLeave(isLeave)

      setRewards(
        account.address ? BigInt(pendingReward) + BigInt(notWithdrawn) : 0
      );
      setIsContractInfoLoaded(true);
    } catch (e) {
      console.error(e.message);
    }
  }, [
    account,
    walletBalance,
    readStake,
    readPendingReward,
    readMinWithdrawValue,
    readMinDepositValue,
  ]);

  useEffect(() => {
    async function getAccount() {
      if (account.isConnected) {
        if (account.address) setAddress(account.address);
        loadData();
      } else {
        setAddress(null);
        setRewards(0);
      }
    }
    async function getRefferals() {
      if (account.isConnected) {
        try {
          console.log("Load referrals info");
          const referralUsers = await readReferralUsersIndexed;
          setReferralUsers(referralUsers);
        } catch (e) {
          console.error("loadReferrals", e.message);
        }
      }
    }
    getAccount();
    getRefferals();
  }, [account.isConnected, account.address, loadData]);

  return (
    <Web3Context.Provider
      value={{
        update,
        connect,
        makeDeposit,
        withdraw,
        withdrawReferral,
        reinvestReferral,
        reinvest,
        referralUsers,
        minDepositValue,
        minWithdrawValue,
        shouldUpdate,
        balance,
        investmentAmount,
        interestRate,
        isDeposited,
        partnerAddress,
        lastInvestTimestamp,
        rewards,
        address,
        isContractInfoLoaded,
        isLeave
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

const useWalletConnectorContext = () => useContext(Web3Context);
export { WalletConnectContext, useWalletConnectorContext };
