import { useEffect, useState } from "react";
import styles from "./Intro.module.sass";
import Cookies from "universal-cookie";
import Button from "../../components/Button";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useDisconnect, useAccount } from "wagmi";
import { truncateInside } from "../../tools/common";
import { useTranslation } from "react-i18next";
import { Desktop } from "../../components/Responsive";
import SettingsForm from "../../components/SettingsForm";
import { useWalletConnectorContext } from "../../context/contract";
import OnboardingOfferModal from "../../components/Popups/OnboardingOfferModal";

import connectArrowImg from "../../assets/img/connect-arrow.svg"
import walletConnectImg from "../../assets/img/wallet-connect-img.svg"

const Intro = ({
  openedUserSettings,
  onboardingDriver,
  onboardingStage,
  setOnboardingStage,
}) => {
  const { isDeposited, referralUsers, investmentAmount, isContractInfoLoaded } =
    useWalletConnectorContext();
  const cookies = new Cookies();
  const { t, i18n, ready } = useTranslation();
  const [isMounted, setIsMounted] = useState(false);
  const onboardingStatus = cookies.get("onboardingStatus");
  const [connectWalletText, setConnectWalletText] = useState(
    t("header.btn_connect")
  );
  // const [numberTodayDeposits, setNumberTodayDeposits] = useState(null);

  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false);
  // const [filteredOnboardingSteps, setFilteredOnboardingSteps] = useState([]);

  const { address, status, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const { open } = useWeb3Modal();

  const connectHandler = () => {
    isConnected ? disconnect() : open();
  };

  useEffect(() => {
    setIsMounted(!isMounted);
  }, []);

  useEffect(() => {
    setConnectWalletText(
      address ? truncateInside(address) : t("header.btn_connect")
    );
  }, [address, ready, i18n.language]);

  useEffect(() => {
    // setFilteredOnboardingSteps(
    //   onboardingSteps.filter((item) => !!document.querySelector(item.element))
    // );
    if (
      isConnected &&
      isContractInfoLoaded &&
      !isDeposited &&
      !referralUsers?.length &&
      onboardingStatus &&
      onboardingStatus[0] &&
      !onboardingStatus[1]
    ) {
      setIsOnboardingModalOpen(true);
      setOnboardingStage(1);
    } else {
      if (
        isConnected &&
        isContractInfoLoaded &&
        !referralUsers?.length &&
        onboardingStatus &&
        onboardingStatus[1] &&
        !onboardingStatus[2] &&
        investmentAmount
      ) {
        setIsOnboardingModalOpen(true);
        setOnboardingStage(2);
      }
    }
  }, [
    isConnected,
    isContractInfoLoaded,
    isDeposited,
    investmentAmount,
    referralUsers,
    ready,
  ]);

  useEffect(() => {
    /* refresh driver when profit section appended */
    const timerBlock = document.getElementById("onboaring__referralTimer");
    const profitBalanceBlock = document.getElementById(
      "onboaring__profitBalanceCard"
    );
    if (onboardingStage !== 2) return;
    onboardingDriver.refresh();

    timerBlock &&
      profitBalanceBlock &&
      timerBlock.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [referralUsers]);

  return (
    <>
      <section className={styles.intro}>
        <div className="container">
          <h1 className={styles.title}>{t("intro.title")}</h1>
          <SettingsForm opened={openedUserSettings} />
          {/* <section className={styles.connect}>
            <div className={styles.connectContent}>
              <div className={styles.connectLabel}>
                {t("intro.firstStep.label")}
              </div>
              <h2 className={styles.connectTitle}>
                {t("intro.firstStep.title")}
              </h2>
            </div>
            <div className={styles.connectImage}>
              <img
                className={styles.connectImageI}
                src={walletConnectImg}
                width={t("intro.firstStep.image.width")}
                height={t("intro.firstStep.image.height")}
                alt=""
                loading="lazy"
              />
            </div>
            <div className={styles.connectAside}>
              <div className={styles.connectArrow}>
                <div className={styles.connectArrowLabel}>
                  {t("intro.firstStep.buttonHint")}
                </div>
                <Desktop>
                  <img
                    className={styles.connectArrowIcon}
                    src={connectArrowImg}
                    width={150}
                    height={42}
                    alt=""
                    loading="lazy"
                  />
                </Desktop>
              </div>
              <Button
                id="onboaring__connectWallet"
                text={connectWalletText}
                className={styles.connectButton}
                onClick={connectHandler}
                size="Md"
              />
            </div>
          </section> */}
          {/* )} */}
          {/* <div className={styles.smallTitle}>
          <span className={styles.accent}>{t("intro.smallTitle")}</span>
        </div> */}
          {/* <div className={styles.rates}>
          <InterestRates interesRateList={interesRateList} />
        </div> */}
          {/* <div className={styles.indexes}>
          <Indexes indexesList={indexesList} />
        </div> */}
        </div>
      </section>

      {isMounted && isOnboardingModalOpen && (
        <OnboardingOfferModal
          setIsPopupOpen={setIsOnboardingModalOpen}
          stage={onboardingStage}
          onboardingDriver={onboardingDriver}
        />
      )}
    </>
  );
};

export default Intro;
