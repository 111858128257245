import s from "./TransactionsHistory.module.sass";
import cn from "classnames";
import { formatEther } from "viem";
import { useTranslation } from "react-i18next";
import { truncateString, floatCut } from "../../tools/common";

export const TransactionsHistory = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className={s.tableWrapper}>
      <div className={s.transactionsTable}>
        <div className={s.transactionsTable__header}>
          <div className={s.row}>
            <div className={s.item}>
              {t("infoSections.transactions.table.headerCols.0")}
            </div>
            <div className={s.item}>
              {t("infoSections.transactions.table.headerCols.1")}
            </div>
            <div className={s.item}>
              {t("infoSections.transactions.table.headerCols.2")}
            </div>
            <div className={s.item}>
              {t("infoSections.transactions.table.headerCols.3")}
            </div>
          </div>
        </div>
        <div className={s.transactionsTable__body}>
          {data &&
            data.map((el, i) => {
              const method = el.functionName.split("(")[0];
              let date = new Date(el.timeStamp * 1000).toLocaleString();
              if (!date) return;
              date = date.split(", ");
              const time = `${date[1].split(":")[0]}:${date[1].split(":")[1]}`;
              date = `${date[0].split(".")[0]}/${date[0].split(".")[1]}/${
                date[0].split(".")[2]
              }`;
              return (
                el.functionName && (
                  <div className={s.row} key={`transaction-item-${i}`}>
                    <div className={cn(s.item, s.method)}>
                      <span className={cn(s[method], s.inner)}>{method}</span>
                    </div>
                    <div className={cn(s.item, s.date)}>
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="8.5" cy="8.5" r="8" stroke="#252526" />
                        <path d="M8.5 2V8.5L12.5 10" stroke="#2F2F2F" />
                      </svg>
                      <span className={s.time}>{time}</span>
                      <span className={s.date}>{date}</span>
                    </div>
                    <div className={cn(s.item, s.from)}>
                      <span className={s.address}>
                        {truncateString(el.from, 33)}
                      </span>
                      <span
                        className={s.icon}
                        onClick={(e) => {
                          navigator.clipboard.writeText(el.from);
                          // e.target.classList.add(styles.copied);
                        }}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.2"
                            d="M16.875 3.125V13.125H13.125V6.875H6.875V3.125H16.875Z"
                            fill="#016938"
                          />
                          <path
                            d="M16.875 2.5H6.875C6.70924 2.5 6.55027 2.56585 6.43306 2.68306C6.31585 2.80027 6.25 2.95924 6.25 3.125V6.25H3.125C2.95924 6.25 2.80027 6.31585 2.68306 6.43306C2.56585 6.55027 2.5 6.70924 2.5 6.875V16.875C2.5 17.0408 2.56585 17.1997 2.68306 17.3169C2.80027 17.4342 2.95924 17.5 3.125 17.5H13.125C13.2908 17.5 13.4497 17.4342 13.5669 17.3169C13.6842 17.1997 13.75 17.0408 13.75 16.875V13.75H16.875C17.0408 13.75 17.1997 13.6842 17.3169 13.5669C17.4342 13.4497 17.5 13.2908 17.5 13.125V3.125C17.5 2.95924 17.4342 2.80027 17.3169 2.68306C17.1997 2.56585 17.0408 2.5 16.875 2.5ZM12.5 16.25H3.75V7.5H12.5V16.25ZM16.25 12.5H13.75V6.875C13.75 6.70924 13.6842 6.55027 13.5669 6.43306C13.4497 6.31585 13.2908 6.25 13.125 6.25H7.5V3.75H16.25V12.5Z"
                            fill="#016938"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className={cn(s.item, s.amount)}>
                      {el.value > 0
                        ? `${floatCut(+formatEther(el.value), 3)} BNB`
                        : "-"}
                    </div>
                  </div>
                )
              );
            })}
        </div>
      </div>
    </div>
  );
};
