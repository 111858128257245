import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import s from "./AddPartnerPlug.module.sass";
import cn from "classnames";
import { toast } from "react-hot-toast";
import Button from "../Button";
import { sha256 } from "js-sha256";
import { useSignMessage } from "wagmi";
import { useWalletConnectorContext } from "../../context/contract";
import { getContract, getServerTimestamp, userRegistrationRequest, bugReport } from "../../config/requests/requests";
import { formValidator } from "../../tools/common";
import { mainDomain } from "../../config/constants";

const AddPartnerPlug = ({
  close,
  refCode,
  altRef,
}) => {
  const { t } = useTranslation();
  const { address, isDeposited } = useWalletConnectorContext();

  const [requestedTimestamp, setRequestedTimestamp] = useState(null)

  const inputRef = useRef(null);

  const { signMessage, data, isLoading, isError, error } = useSignMessage({
    onSuccess(data) {
      const {value: inviterValue} = inputRef.current;
      const ref = altRef ? altRef.toLowerCase() : inviterValue.toLowerCase()
      // registration request
      userRegistrationRequest(address, ref, requestedTimestamp, data).then(() => {
        close();
        localStorage.setItem('partner-address', inviterValue);
      }).catch(err => {
        console.error('userRegistrationRequest error: ', err)
        toast.error(t("registration.errors.somethingWrongContactSupport"));
        bugReport(
          `Registration (userRegistrationRequest) error: ${err}; \n Response data: ${err?.response?.data}\n Address: ${address}; \n Inviter: ${ref}\n Timestamp: ${requestedTimestamp}\n Signature: ${data}`
        ).then(() => console.log("bug successfully sended"));
      })
    },
    onError(error) {
      console.error("Error signing message:", error);
    },
  });

  const signRequest = () => {
    const {value: inviterValue} = inputRef.current;
    if(!inviterValue) return
    getServerTimestamp().then(r => {
      const {data: timestamp} = r;
      setRequestedTimestamp(timestamp)
      const nonce = `${address.toLowerCase()}${(altRef ? altRef.toLowerCase() : inviterValue.toLowerCase())}true${timestamp}`
      const nonceHash = sha256(nonce)
      signMessage({ message: `Sign the message to confirm your registration! Nonce:${nonceHash}` });
    })
  }

  const addPartnerHandler = () => {
    const {value} = inputRef.current;

    if(value) {
      if(address === value) {
        toast.error(t("registration.errors.clientAddressIsTheSameAsReferral"));
        return console.log('your address can\'t be the same as that your referral');
      }

      if(!formValidator.checkReferral(value).status) {
        toast.error(t("registration.errors.adressInvalidFormat"));
        return console.log('invalid referral code.');
      }

      const readStake = getContract(altRef || value, "stake");

      readStake.then((resp) => {
        if (resp) {
          const [, , , , , deposited] = resp;
          if(deposited) signRequest()
          if(!deposited) toast.error(t("registration.errors.refAddressDoesntHaveDeposit"));
        }
      }).catch((err) => {
        toast.error(t("registration.errors.somethingWrong"));
        console.log("getContract: stake", err);
      });
    }
  }

  return (
    <div className={s.addParnerPlugWrapper}>
      <div className={s.addParnerPlug}>
        <div className={s.addParnerPlug__text}>
          {
            !isDeposited ? (<span>{t("registration.title.forNewClients")}</span>) : (<span>{t("registration.title.forOldClients")}</span>)
          }
        </div>
        <div className={s.addParnerPlug__inputWrapper}>
          <input type="text" name="parner-address" defaultValue={refCode} className={s.addParnerPlug__input} ref={inputRef} disabled={isDeposited} />
        </div>
        {isDeposited && <div className={s.addParnerPlug__notify}>{t("registration.notifications.changingInviterNotAvailable")}</div>}
        <div className={cn(s.addParnerPlug__notify, s.redText)}>{t("registration.notifications.confirmationNotCitizenUnitedStates")}</div>
        <div className={s.addParnerPlug__control}>
          <Button text={t("registration.buttons.returnToSite.label")} className={cn(s.button, s.addParnerPlug__returnButton)} link={`https://${mainDomain}`} />
          <Button text={t("registration.buttons.confirm.label")} className={cn(s.button, s.addParnerPlug__addParner)} onClick={addPartnerHandler} />
        </div>
      </div>
    </div>
  );
};

export default AddPartnerPlug;
