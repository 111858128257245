import { useState } from "react";
import Tooltip from "../Tooltip/Tooltip";
import classNames from "classnames";
import styles from "./SwitchButton.module.sass";

const SwitchButton = ({
  label,
  subLabel,
  toolTipText,
  id,
  className,
  isChecked,
  setState,
}) => {
  const [switchBoxChecked, setSwitchBoxChecked] = useState(isChecked);

  const switchHandler = () => {
    setSwitchBoxChecked(!switchBoxChecked);
    setState(!switchBoxChecked);
  };

  return (
    <div className={classNames(
      styles.switchButton,
      styles[className],
      switchBoxChecked && styles.switchButtonChecked
      )}>
      <div className={styles.switch}>
        <input
          type="checkbox"
          id={id ? id : "switch-item"}
          defaultValue={id ? id : "switch-item"}
          checked={switchBoxChecked}
          readOnly={true}
        />
        <label htmlFor={id ? id : "switch-item"} onClick={switchHandler}>
          <span />
        </label>
        <div className={styles.labelWrapper}>
          <div className={styles.switchTitle}>
            <span className={styles.label}>{label}</span>
            {toolTipText && (
              <Tooltip
                classIcon={classNames(
                  "status-line-tooltip",
                  styles.tooltipIcon
                )}
                classBox="status-line-tooltip-box"
              >
                {toolTipText}
              </Tooltip>
            )}
          </div>
          <div className={styles.subLabel}>{subLabel}</div>
        </div>
      </div>
    </div>
  );
};

export default SwitchButton;
