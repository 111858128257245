import { useTranslation } from "react-i18next";
import { useAccount } from "wagmi";
import { TabletDesktop, Mobile } from "../../components/Responsive";
import { getUndepositedReferrals } from "../../config/requests/requests";
import styles from "./ReferralsList.module.sass";
import { useEffect, useState } from "react";

const ReferralsList = () => {
  const { t } = useTranslation();
  const { address } = useAccount();

  const [referralList, setReferralList] = useState(null);

  useEffect(() => {
    if (address) {
      getUndepositedReferrals(address).then((resp) => {
        // setReferralList([ // example
        //   {
        //     "Address": "0x2e231e3dfe08af2dda06ac795c89816fb4fb169c",
        //     "Mail": "null",
        //     "Name": "Ivan",
        //     "Telegram": "telegramNick",
        //     "Whatsapp": '+380991111111',
        //     "CreatedAt": 1731514985
        //   }
        // ])
        if (resp?.data) setReferralList(resp.data);
        if (!resp?.data) setReferralList(null);
      }).catch(err => console.log('ReferralsList Component -> getUndepositedReferrals request error', err));;
    }
  }, [address]);

  return (
    referralList && (
      <section className={styles.refferalsList}>
        <div className="container">
          <div className={styles.inner}>
            <h2 className={styles.title}>
              {t("refferalsWithoutDeposit.title")}
            </h2>
            <div className={styles.table}>
              <TabletDesktop>
                <div className={styles.head}>
                  <div className={styles.col}>
                    <div className={styles.label}>
                      {t("refferalsWithoutDeposit.name")}
                    </div>
                  </div>
                  <div className={styles.col}>
                    <div className={styles.label}>
                      {t("refferalsWithoutDeposit.walletAddress")}
                    </div>
                  </div>
                  <div className={styles.col}>
                    <div className={styles.label}>
                      {t("refferalsWithoutDeposit.contacts")}
                    </div>
                  </div>
                </div>
              </TabletDesktop>
              <div className={styles.list}>
                {referralList.map((item, i) => (
                  <div key={`refferals-list-item-${i}`} className={styles.row}>
                    <div className={styles.col}>
                      <Mobile>
                        <div className={styles.label}>
                          {t("refferalsWithoutDeposit.name")}
                        </div>
                      </Mobile>
                      <div className={styles.value}>{item.Name ? item.Name : '-'}</div>
                    </div>
                    <div className={styles.col}>
                      <Mobile>
                        <div className={styles.label}>
                          {t("refferalsWithoutDeposit.walletAddress")}
                        </div>
                      </Mobile>
                      <div className={styles.value}>{item.Address ? item.Address : '-'}</div>
                    </div>
                    <div className={styles.col}>
                      <Mobile>
                        <div className={styles.label}>
                          {t("refferalsWithoutDeposit.contacts")}
                        </div>
                      </Mobile>
                      <div className={styles.contact}>
                        {item.Login && (
                          <a
                            className={styles.contactLink}
                            href={`mailto:${item.Login}`}
                          >
                            <svg
                              className={styles.contactLinkIcon}
                              width="16"
                              height="11"
                              viewBox="0 0 16 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M8.0018 6.38477L14.9249 0.384766H1.07873L8.0018 6.38477ZM6.13187 5.72057L8.0018 7.25545L9.84243 5.72057L14.9249 10.0771H1.07873L6.13187 5.72057ZM0.617188 9.61553V0.846304L5.69411 5.23092L0.617188 9.61553ZM15.3864 9.61553V0.846304L10.3095 5.23092L15.3864 9.61553Z"
                                fill="#2E2F2B"
                              />
                            </svg>
                          </a>
                        )}
                        {item.Whatsapp && (
                          <a
                            className={styles.contactLink}
                            href={`https://wa.me/${item.Whatsapp}`}
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M13.5312 2.3551C12.0432 0.864704 10.0608 0.0439039 7.9512 0.0415039C3.6048 0.0415039 0.0647999 3.5791 0.0647999 7.9279C0.0647999 9.3175 0.4272 10.6759 1.1184 11.8711L0 15.9583L4.1808 14.8615C5.3328 15.4903 6.6288 15.8215 7.9488 15.8215H7.9512C12.2976 15.8215 15.8352 12.2839 15.8376 7.9351C15.84 5.8279 15.0216 3.8455 13.5312 2.3551ZM7.9536 14.4895H7.9512C6.7752 14.4895 5.6208 14.1727 4.6152 13.5751L4.3752 13.4335L1.8936 14.0839L2.556 11.6647L2.4 11.4175C1.7448 10.3735 1.3968 9.1687 1.3968 7.9279C1.3992 4.3135 4.3392 1.3735 7.956 1.3735C9.708 1.3735 11.352 2.0575 12.5904 3.2959C13.8288 4.5343 14.5104 6.1831 14.508 7.9327C14.508 11.5495 11.5656 14.4895 7.9536 14.4895ZM11.5488 9.5791C11.352 9.4807 10.3824 9.0031 10.2024 8.9383C10.0224 8.8735 9.8904 8.8399 9.7584 9.0367C9.6264 9.2335 9.2496 9.6775 9.1344 9.8095C9.0192 9.9415 8.904 9.95831 8.7072 9.85991C8.5104 9.76151 7.8744 9.5527 7.1232 8.8807C6.5376 8.3575 6.1416 7.7119 6.0264 7.5151C5.9112 7.31831 6.0144 7.2103 6.1128 7.1119C6.2016 7.0231 6.3096 6.8815 6.408 6.7663C6.5064 6.6511 6.54 6.5695 6.6048 6.4375C6.6696 6.3055 6.6384 6.1903 6.588 6.0919C6.5376 5.9935 6.144 5.0239 5.9808 4.6279C5.82 4.2439 5.6592 4.2967 5.5368 4.2895C5.4216 4.2847 5.2896 4.2823 5.16 4.2823C5.028 4.2823 4.8144 4.3327 4.6344 4.5295C4.4544 4.7263 3.9456 5.2039 3.9456 6.1735C3.9456 7.1431 4.6512 8.0815 4.7496 8.2111C4.848 8.3431 6.1392 10.3327 8.1168 11.1871C8.5872 11.3911 8.9544 11.5111 9.24 11.6023C9.7128 11.7535 10.1424 11.7319 10.4808 11.6815C10.86 11.6239 11.6472 11.2039 11.8104 10.7455C11.9736 10.2847 11.9736 9.8911 11.9256 9.8095C11.8776 9.7279 11.7456 9.6799 11.5488 9.5791Z"
                                fill="#2E2F2B"
                              />
                            </svg>
                          </a>
                        )}
                        {item.Telegram && (
                          <a
                            className={styles.contactLink}
                            href={`https://telegram.me/${item.Telegram}`}
                          >
                            <svg
                              className={styles.contactLinkIcon}
                              width="14"
                              height="12"
                              viewBox="0 0 14 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.49029 4.74021L13.0603 0.279207C13.5973 0.0852074 14.0663 0.410208 13.8923 1.22221L13.8933 1.22121L11.9233 10.5022C11.7773 11.1602 11.3863 11.3202 10.8393 11.0102L7.83929 8.79921L6.39229 10.1932C6.23229 10.3532 6.09729 10.4882 5.78729 10.4882L6.00029 7.43521L11.5603 2.41221C11.8023 2.19921 11.5063 2.07921 11.1873 2.29121L4.31629 6.61721L1.35429 5.69321C0.711286 5.48921 0.697286 5.05021 1.49029 4.74021Z"
                                fill="#2E2F2B"
                              />
                            </svg>
                          </a>
                        )}
                        {!item.Telegram && !item.Whatsapp && '-'}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  );
};

export default ReferralsList;
