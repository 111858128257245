import { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  notificationsRequest,
  bnbRateRequest,
} from "../../config/requests/requests";
import { formatEther } from "viem";
import { notificationInfo, notificationCloseBtn } from "../../assets/icons";
import logo from "../../assets/img/antidote-logo.png";
import * as exchangeImages from "../../assets/exchanges";

/* Functions: open notification */
const triggerSpoiler = (e) => {
  const target = e.target;
  const notificationsContainer = target.closest(".notifications");
  const notificationSpoiler = target
    .closest(".notify-item")
    .querySelectorAll(".spoiler");
  const currentNotify = target.closest(".notify-item");
  let spoilersSumHeight = 0;

  if (!currentNotify.classList.contains("expended")) {
    currentNotify.classList.add("expended");

    notificationSpoiler.forEach((spoiler) => {
      spoilersSumHeight += spoiler.querySelector(".inner").offsetHeight;
    });
    if (notificationsContainer.classList.contains("collapse")) {
      notificationsContainer.style.height =
        Math.ceil(currentNotify.offsetHeight) +
        Math.ceil(spoilersSumHeight) +
        2 +
        "px";
    }

    notificationSpoiler.forEach((spoiler) => {
      spoiler.style.height =
        spoiler.querySelector(".inner").offsetHeight + "px";
    });

    // if notification don't have space bottom for expending - scroll bottom
    let innerHeight =
        notificationsContainer.querySelector(".inner").offsetHeight, // innder height with top padding
      currentExpendedHeight =
        currentNotify.offsetTop + currentNotify.offsetHeight,
      currentSpaceBottom = innerHeight - currentExpendedHeight;
    if (currentNotify.offsetHeight + spoilersSumHeight > currentSpaceBottom) {
      setTimeout(() => {
        notificationsContainer.scrollBy({
          top: currentNotify.offsetTop,
          behavior: "smooth",
        });
      }, 500);
    }
  } else {
    currentNotify.classList.remove("expended");

    notificationSpoiler.forEach((spoiler) => {
      spoilersSumHeight += spoiler.querySelector(".inner").offsetHeight;
    });
    if (notificationsContainer.classList.contains("collapse")) {
      notificationsContainer.style.height =
        Math.ceil(currentNotify.offsetHeight) -
        Math.ceil(spoilersSumHeight) +
        2 +
        "px";
    }

    notificationSpoiler.forEach((spoiler) => {
      spoiler.style.height = 0;
    });
  }
};
/* End functions: open notification */

/* Functions: Stack animation effect */
function stack_animation() {
  const allNotifyWrap = document.querySelector(".notify-wrap .notifications");
  const allNotifyItems = allNotifyWrap.querySelectorAll(".notify-item");

  allNotifyItems.forEach((el) => {
    if (
      Math.trunc(el.getBoundingClientRect().bottom - window.innerHeight) >= -120
    ) {
      let spaceBottom = Math.trunc(
        el.getBoundingClientRect().bottom - window.innerHeight + 120
      );
      if (!!el.nextElementSibling)
        el.nextElementSibling.style.transform = `scale(${
          1 - spaceBottom / 2000 > 1 ? 1 : 1 - spaceBottom / 2000
        })`;
    } else {
      if (el.nextElementSibling)
        el.nextElementSibling.style.transform = `scale(1)`;
    }
  });
}
/* End functions: Stack animation effect */

/* Mobile version -> Remove notifications container by swipe */
let startX = 0,
  deltaX = 0;
const removeContainerBySwipeStart = (event) => {
  const allNotifyWrap = document.querySelector(".notify-wrap .notifications");

  if (allNotifyWrap.classList.contains("collapse")) {
    startX = event.changedTouches[0].clientX;
    allNotifyWrap.style.transition = "unset";
  }
};

const removeContainerBySwipeMove = (event) => {
  const allNotifyWrap = document.querySelector(".notify-wrap .notifications");

  if (allNotifyWrap.classList.contains("collapse")) {
    event.preventDefault();
    deltaX = event.changedTouches[0].clientX - startX;
    allNotifyWrap.style.transform = "translateX(" + deltaX + "px)";
    allNotifyWrap.style.opacity = 1 - Math.abs(deltaX) / 100;
  }
};

const removeContainerBySwipeEnd = () => {
  const allNotifyWrap = document.querySelector(".notify-wrap .notifications");

  if (allNotifyWrap.classList.contains("collapse")) {
    allNotifyWrap.style.opacity = 1;
    allNotifyWrap.style.transition = "all 0.5s ease";
    allNotifyWrap.style.transform = "translateX(0)";
    if (Math.abs(deltaX) > 80) {
      allNotifyWrap.innerHTML = "";
    }
  }
  // refresh coordinates
  (startX = 0), (deltaX = 0);
};
/* End Mobile version -> Remove notifications item by swipe */

/* Mobile version -> Remove notifications item by swipe */
const removeItemBySwipeStart = (event) => {
  let element = event.target || event.srcElement; // get target element
  element = !element.classList.contains("notify-item")
    ? element.closest(".notify-item")
    : element; // get notify-item
  const allNotifyWrap = document.querySelector(".notify-wrap .notifications");

  if (allNotifyWrap.classList.contains("extends")) {
    startX = event.changedTouches[0].clientX;
    element.style.transition = "unset";
  }
};

const removeItemBySwipeMove = (event) => {
  let element = event.target || event.srcElement; // get target element
  element = !element.classList.contains("notify-item")
    ? element.closest(".notify-item")
    : element; // get notify-item
  const allNotifyWrap = document.querySelector(".notify-wrap .notifications");

  if (
    !element.classList.contains("expended") &&
    allNotifyWrap.classList.contains("extends")
  ) {
    deltaX = event.changedTouches[0].clientX - startX;
    if (Math.abs(deltaX) > 20) {
      element.style.transform =
        "translateX(" + (deltaX < 0 ? deltaX + 20 : deltaX - 20) + "px)";
      element.style.opacity = 1 - (Math.abs(deltaX) - 20) / 100;
    }
  }
};

const removeItemBySwipeEnd = (event) => {
  let element = event.target || event.srcElement; // get target element
  element = !element.classList.contains("notify-item")
    ? element.closest(".notify-item")
    : element; // get notify-item
  const allNotifyWrap = document.querySelector(".notify-wrap .notifications");

  if (allNotifyWrap.classList.contains("extends")) {
    if (Math.abs(deltaX) < 80) {
      element.style.opacity = 1;
      element.style.transition = "all 0.5s ease";
      element.style.removeProperty("transform");
    } else {
      element.classList.add("removed");
      allNotifyWrap.classList.add("smooth-stack-animation");
      // recompute stack animation
      let removeTimer = setTimeout(() => {
        element.remove(); // remove item after animation
        stack_animation();
        clearTimeout(removeTimer);
      }, 500);
      // stack animation timer -> remove smooth class for animate stack_animation
      let stackAnimTimer = setTimeout(() => {
        allNotifyWrap.classList.remove("smooth-stack-animation");
        clearTimeout(stackAnimTimer);
      }, 1000);
    }
  }
  // refresh coordinates
  (startX = 0), (deltaX = 0);
};
/* End Mobile version -> Remove notifications item by swipe */

export function NotificationsList() {
  const { t } = useTranslation();
  const [bnbRate, setBnbRate] = useState(null);
  const [botNotificationExtends, setbotNotificationExtends] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [newNotification, setNewNotification] = useState([]);
  const toolTip = useRef(null);
  
  /* Add new notification every 10 seconds (10000 in ms) */
  useEffect(() => {
    // Interval call new notification every minute
    const newNotificationQuery = setInterval(async () => {
      notificationsRequest().then((notifications) => {
        let currentNotificationsLastIndex = notifications.data[0].ID; // last notification in current notifications list
        let entryIndex = notificationList[0]?.ID;

        if (currentNotificationsLastIndex && entryIndex) {
          if (entryIndex < currentNotificationsLastIndex) {
            notifications.data[notifications.data.length - 1]["status"] = "new";
            setNotificationList(notifications?.data);
            /* Append Animation */
            document
              .querySelector(".notify-item:first-child")
              .classList.add("new"); // add 'new' for start current animation
            setTimeout(() => {
              document
                .querySelector(".notify-item.new")
                ?.classList.remove("new"); // remove old 'new' class for start next animation
            }, 1000);
            /* end append animation */
          }
        }

        setNewNotification(notifications);
        clearInterval(newNotificationQuery);
      });
    }, 10000);
  }, [newNotification]);
  /* End add new notification every 10 seconds (10000 in ms) */

  /* Query to API and set their position to states */
  useEffect(() => {
    bnbRateRequest().then((out) => setBnbRate(out[1]));
    notificationsRequest().then((out) => setNotificationList(out.data));
  }, []);
  /* End query to API and set their position to states */

  /* Do stack animation and remove shadows on items on same positions after loaded page */
  useEffect(() => {
    const allNotifyWrap = document.querySelector(".notify-wrap .notifications");
    const allNotifyItems = allNotifyWrap.querySelectorAll(".notify-item");

    // remove boxes shadow if coordinates of blocks is matches
    allNotifyWrap.addEventListener("scroll", () => {
      allNotifyItems.forEach((el, i) => {
        if (i > 0) {
          if (el.offsetTop == allNotifyItems[i - 1].offsetTop) {
            el.style.boxShadow = "unset";
          } else {
            el.style.boxShadow = "0px 10px 24px rgba(0, 0, 0, 0.1)";
          }
        }
      });

      stack_animation();
    });
  }, []);
  /* End do stack animation and remove shadows on items on same positions after loaded page */

  /* Compute all exchanges indexes */
  useMemo(() => {
    notificationList.map((notification) => {
      /* Define Time output */
      if (notification.Timestamp) {
        let dateSource = new Date(notification.Timestamp * 1000).toUTCString();

        if (dateSource) {
          let localDate = dateSource.split(" ");
          localDate = localDate[localDate.length - 2].split(":");
          notification["date"] = `${localDate[0]}:${localDate[1]}`;
        }
      }
      /* End / Define Time output */

      let pathes = [],
        botPathes = notification.Path;
      let firstExchange = botPathes[0]?.split("/")[0];

      let exchangedProfit = Number(formatEther(notification.Profit)); //decimal 2

      botPathes.map((value, index) => {
        let pair = `${value.replace("/", " —> ")}`;

        let pathItem = {};
        pathItem[pair] =
          index === 0
            ? `${Number(formatEther(notification.Prices[index])).toFixed(
                2
              )} / ${Number(
                formatEther(notification.Prices[index + 1])
              ).toFixed(2)}`
            : `${Number(formatEther(notification.Prices[index])).toFixed(
                2
              )} / ${Number(
                formatEther(notification.Prices[index + 1])
              ).toFixed(2)}`;

        pathes.push(pathItem);
      });

      // set new computed values
      notification["profit_usd"] = `${Number(
        parseFloat(formatEther(notification.Profit)) * bnbRate
      ).toFixed(2)}$`; // decimal = 2

      notification["volumeUsed"] = `${Number(
        formatEther(notification.Prices[0])
      ).toFixed(2)} ${firstExchange}`; // decimal = 2

      notification["percentForVolumeUsedDecimal"] = `${
        notification.PercentForVolumeUsed / 10000
      }%`; // decimal = 2

      notification["ready_path"] = pathes;

      notification["exchanged_profit"] = `${parseFloat(
        Number(exchangedProfit).toFixed(2)
      )} BNB`;
    });
  }, [bnbRate, notificationList]);
  /* End compute all exchanges indexes */

  /* Calculate notifications container and add eventlisteners */
  useEffect(() => {
    const allNotifyWrap = document.querySelector(".notify-wrap .notifications");
    const allNotifyItems = allNotifyWrap.querySelectorAll(".notify-item");
    const notificationsLength = allNotifyItems.length;

    // Set height of collapsed notifications container
    if (
      allNotifyItems.length > 0 &&
      !allNotifyWrap.closest(".notify-wrap").classList.contains("extends") &&
      !allNotifyItems[0].classList.contains("expended")
    ) {
      allNotifyWrap.style.height =
        allNotifyItems[1].getBoundingClientRect().height + "px";
      // opacity animation
      setTimeout(() => {
        allNotifyWrap.style.opacity = 1;
      }, 800);
    }

    // reverse z-index
    allNotifyItems.forEach((el, i) => {
      el.style.zIndex = notificationsLength - i;
    });

    /* Swipe and Drop functionality */
    allNotifyWrap.addEventListener("touchstart", removeContainerBySwipeStart); // container
    allNotifyWrap.addEventListener("touchmove", removeContainerBySwipeMove); // container
    allNotifyWrap.addEventListener("touchend", removeContainerBySwipeEnd); // container
    allNotifyItems.forEach((el) => {
      el.addEventListener("touchstart", removeItemBySwipeStart); // item
      el.addEventListener("touchmove", removeItemBySwipeMove); // item
      el.addEventListener("touchend", removeItemBySwipeEnd); // item
    });
    /* End Swipe and Drop functionality */

    /* Remove all listeners on render */
    return () => {
      allNotifyWrap.removeEventListener(
        "touchstart",
        removeContainerBySwipeStart
      ); // container
      allNotifyWrap.removeEventListener(
        "touchmove",
        removeContainerBySwipeMove
      ); // container
      allNotifyWrap.removeEventListener("touchend", removeContainerBySwipeEnd); // container
      allNotifyItems.forEach((el) => {
        el.removeEventListener("touchstart", removeItemBySwipeStart); // item
        el.removeEventListener("touchmove", removeItemBySwipeMove); // item
        el.removeEventListener("touchend", removeItemBySwipeEnd); // item
      });
    };
    /* End Remove all listeners on render */
    /* End Swipe and Drop functionality */
  }, [notificationList]);
  /* End calculate notifications container and add eventlisteners */

  /* Return all notifications to their original position (scroll up), collapse spoilers and main container */
  useEffect(() => {
    const notificationWrapper = document.querySelector(".notifications-wrap");
    const notificationWrap = document.querySelector(".notify-wrap");
    const allNotifyWrap = notificationWrap.querySelector(".notifications");
    const allNotifications = allNotifyWrap.querySelectorAll(".notify-item");
    const notificationHeight = allNotifications[0]?.offsetHeight;

    if (allNotifications.length > 0) {
      if (!botNotificationExtends) {
        /* Allow scroll if container of notifications is collapser */
        if (window.innerWidth <= 1180) document.body.style.overflow = "auto";
        /* End allow scroll if container of notifications is collapser */

        allNotifyWrap.scrollTo(0, 0); // set to top

        Array.from(allNotifications)
          ?.slice(1)
          .forEach((el) => {
            // remove all transform scale
            el.style.transform = "";
            // collapse all notifications if we're closed container
            el.classList.remove("expended");
            el.querySelectorAll(".spoiler").forEach((spolier) => {
              spolier.style.height = 0;
            });
          });

        // recompute height of container
        allNotifyWrap.style.height = notificationHeight + "px";
        notificationWrapper.style.height = "";
      } else {
        /* Disable scroll if container of notifications is expended */
        if (window.innerWidth <= 1180) document.body.style.overflow = "hidden";
        /* End disable scroll if container of notifications is expended */

        notificationWrapper.style.height = `calc(${
          window.innerHeight - notificationWrap.getBoundingClientRect().top
        }px)`;
        // allNotifyWrap.querySelector('.inner').style.height = (((allNotifications[1].offsetHeight+1) * allNotifications.length)-10) + 'px'

        allNotifyWrap.style.height = `calc(${
          window.innerHeight - notificationWrap.getBoundingClientRect().top - 33
        }px)`;

        setTimeout(() => {
          stack_animation();
        }, 300);
      }
    }
  }, [botNotificationExtends]);
  /* End return all notifications to their original position (scroll up), collapse spoilers and main container */

  /* Tooltips */
  const handleShowTooltip = (event) => {
    let leftPosition =
        event.target?.getBoundingClientRect().left -
        event.target.closest(".notifications-wrap")?.getBoundingClientRect()
          .left,
      topPosition =
        event.target?.getBoundingClientRect().top -
        event.target.closest(".notifications-wrap")?.getBoundingClientRect()
          .top,
      tooltipContent = event.target.getAttribute("tooltip-content");

    if (
      toolTip.current &&
      tooltipContent &&
      typeof leftPosition == "number" &&
      typeof topPosition == "number"
    ) {
      toolTip.current.innerHTML = `<p>${
        tooltipContent ? tooltipContent : ""
      }</p>`; // set tooltip content into toolpit container
      toolTip.current.classList.add("show");
      if (event.target.closest(".currency-ico"))
        toolTip.current.classList.add("exchanger-ico"); // for exchanger ico
      toolTip.current.style.cssText = `--lance-pos: ${
        event.target?.getBoundingClientRect().left -
        toolTip.current.getBoundingClientRect().left
      }px`; // lance position for mobile
      toolTip.current.style.left =
        leftPosition - toolTip.current?.offsetWidth + "px";
      toolTip.current.style.top = topPosition + "px";
    }
  };
  const handleHideTooltip = () => {
    if (toolTip.current) {
      toolTip.current.classList.remove("show");
      toolTip.current.classList.remove("exchanger-ico"); // for exchanger ico
    }
  };
  /* End tooltip */

  return (
    <div className="notifications-container">
      <div
        className={
          "notify-wrap trading-alert" +
          (botNotificationExtends ? " extends" : " collapse")
        }
      >
        <div
          className={
            "notifications-wrap" +
            (botNotificationExtends ? " extends" : " collapse")
          }
        >
          <div
            className={
              "notifications" +
              (botNotificationExtends ? " extends" : " collapse")
            }
            onClick={(e) => {
              !botNotificationExtends && !e.target.closest(".read-more")
                ? setbotNotificationExtends(!botNotificationExtends)
                : "";
            }}
          >
            <div className="spacer"></div>
            <div className="inner">
              {notificationList.map(function (value, index) {
                return (
                  <div
                    className={`notify-item${
                      value.error == "error" ? " error" : ""
                    }`}
                    key={index}
                  >
                    <div className="spoiler">
                      <div className="inner">
                        <div className="headline">
                          <div className="title">
                            <div className="ico">
                              <img src={logo} />
                            </div>
                            <div className="alert-type">
                              <span>
                                {t("arbitrageBot.card.notificationTitle")}
                              </span>
                            </div>
                            <div
                              className="info-tooltip-trigger"
                              style={{ display: "none" }}
                            >
                              <img
                                src={notificationInfo.src}
                                onMouseEnter={handleShowTooltip}
                                onMouseLeave={handleHideTooltip}
                                tooltip-content={
                                  "arbitrageBot.tooltips.everyone"
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="subject">
                          <span>{t("arbitrageBot.subject")}</span>
                        </div>
                      </div>
                    </div>

                    <div className="community-profit">
                      <div className="inner">
                        <div className="value">
                          <div className="title">
                            {index == 0 ? (
                              <img
                                src={notificationInfo.src}
                                onMouseEnter={handleShowTooltip}
                                onMouseLeave={handleHideTooltip}
                                tooltip-content={"arbitrageBot.tooltips.common"}
                                style={{ display: "none" }}
                              />
                            ) : (
                              ""
                            )}
                            {t("arbitrageBot.card.title")}
                          </div>
                          <div className="profit">{`${
                            value.percentForVolumeUsedDecimal
                          } / ${
                            value.exchanged_profit ? value.exchanged_profit : ""
                          } (${value.profit_usd})`}</div>
                        </div>
                        <div className="currency-ico">
                          {value?.BuyExchange &&
                            exchangeImages[value.BuyExchange.toLowerCase()] ? (
                              <img
                                src={
                                  exchangeImages[value.BuyExchange.toLowerCase()]
                                }
                              />
                            ) : (
                              ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="spoiler">
                      <div className="inner">
                        <div className="volume-used">
                          <div className="inner">
                            <div className="value">
                              <div className="title">
                                {t("arbitrageBot.card.volumeUsedTitle")}
                              </div>
                              <div className="value">{`${value.volumeUsed}`}</div>
                            </div>
                          </div>
                        </div>
                        <div className="item exchange">
                          <span className="title">
                            {t("arbitrageBot.card.exchangeTitle")}
                          </span>
                          <span>{value.Exchange}</span>
                        </div>
                        <div className="item pathes">
                          <div className="path">
                            <div className="title">
                              {t("arbitrageBot.card.pathTitle")}
                            </div>
                            <div className="value">{value.Path.join(" ")}</div>
                          </div>
                          <div className="flow">
                            <div className="value">
                              {value.ready_path?.map((value, index) => {
                                let key = Object.keys(value);

                                return (
                                  <div
                                    key={index}
                                  >{`${key} ${value[key]}`}</div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="action-bar">
                      <div className="time">
                        <span>{value.date}</span>
                      </div>
                      <button className="read-more" onClick={triggerSpoiler}>
                        <span className="more">
                          {t("arbitrageBot.buttons.readMore")}
                        </span>
                        <span className="hide">
                          {t("arbitrageBot.buttons.hide")}
                        </span>
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <button
            className="close"
            onClick={() => {
              setbotNotificationExtends(!botNotificationExtends);
            }}
          >
            <img src={notificationCloseBtn} />
          </button>
          <div className="tooltip" ref={toolTip}></div>
        </div>
      </div>
    </div>
  );
}

export const getStaticProps = async ({ locale }) => {
  const lang = {
    ...(await serverSideTranslations(locale ?? "en", ["common"])),
  };
  console.log("lang", lang);
  return { props: { ...lang } };
};
