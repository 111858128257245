import { WalletConnectContext } from "../context/contract";
import { web3ProjectId } from "../config/constants";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";

import { WagmiConfig } from "wagmi";
import { bsc } from "viem/chains";

import logo from "../assets/img/antidote-logo.png";

// 1. Get projectId
const projectId = web3ProjectId;

// 2. Create wagmiConfig
const metadata = {
  name: "ANTIDOTE",
  description: "ANTIDOTE CABINET",
  url: "https://app.iamantidote.com",
  icons: [logo],
};

const chains = [bsc];
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, chains });

export function Web3Modal({ children }) {
  return (
    <WagmiConfig config={wagmiConfig}>
      <WalletConnectContext>{children}</WalletConnectContext>
    </WagmiConfig>
  );
}