import { useTranslation } from "react-i18next";
import CollapsedList from "../../components/CollapsedList/CollapsedList";
import styles from "./FAQ.module.sass";

const FAQ = () => {
  const { t } = useTranslation();

  const list = [
    {
      title: t("faq.list.0.title"),
      text: t("faq.list.0.text", { amount: "0.3 BNB" }),
    },
    {
      title: t("faq.list.1.title"),
      text: t("faq.list.1.text", {
        amountPercentages:
          "<ul><li>0.3-2.99BNB — 0.9% = 328.5% APR</li><li>3-9.99BNB — 1% = 365% APR</li><li>10-99 — 1.05% = 383% APR</li><li>100-999BNB — 1.09% 397% APR</li><li>1000BNB+ — 1.3% = 475% APR</li></ul>",
        depositAmount: "1 BNB",
        dailyIncome: "0.009 BNB",
      }),
    },
    {
      title: t("faq.list.2.title"),
      text: t("faq.list.2.text"),
    },
    {
      title: t("faq.list.3.title"),
      text: t("faq.list.3.text"),
    },
    {
      title: t("faq.list.4.title"),
      text: t("faq.list.4.text"),
    },
    {
      title: t("faq.list.5.title"),
      text: t("faq.list.5.text"),
    },
  ];

  return (
    <section className={styles.faq} id="faq">
      <h2 className={styles.title}>
        {t("faq.title")}
      </h2>
      <div className={styles.list}>
        <CollapsedList data={list} />
      </div>
    </section>
  );
};

export default FAQ;
