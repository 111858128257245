import { investmentFee, reinvestFee } from "../config/constants";

/* Cut float number */
export function floatCut(num, fixed) {
  var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return num.toString().match(re)[0];
}
// WILL REPLACED WITH ABOVE
// export function floatCut(num: number, fixed: number) {
//   const re = new RegExp(`^-?\\d+(?:\\.\\d{0,${fixed}})?`)
//   const match = num.toString().match(re)
//   return match ? match[0] : ''
// }

/* Truncate function */
export const truncateInside = (str) => {
  return str.length > 14
    ? str.substring(0, 7) + "..." + str.substring(str.length - 7)
    : str;
};
export const truncateString = (str, length = 35) => {
  return str.length > length ? str.substring(0, length) + "..." : str;
};

const interestRates = (value) => {
  switch (true) {
    case value < 0.3:
      return 0;
    case value >= 0.3 && value < 3:
      return 0.9;
    case value >= 3 && value < 10:
      return 1;
    case value >= 10 && value < 100:
      return 1.05;
    case value >= 100 && value < 1000:
      return 1.09;
    case value >= 1000:
      return 1.3;

    default:
      return 0;
  }
};
export { interestRates };

export const calculateProfitPerDay = (investment, interestRate) => {
  return !!Number(investment) ? Number(investment * (interestRate / 100)) : 0;
};

export const calculateProfitPeriod = (profitPerDay, period, decimals = 5) => {
  return !!Number(profitPerDay) ? Number(profitPerDay * period) : 0;
};

export const getInvestmentPayOffDays = (
  investment,
  isActiveFee,
  isReinvest
) => {
  if (Number(investment)) {
    const fee = isActiveFee ? investmentFee / 100 : 0;
    let days = 1,
      profitPerDay = investment * (interestRates(investment) / 100);

    let reinvestSum = Number(investment);

    if (profitPerDay) {
      while (true) {
        if (isReinvest)
          reinvestSum += (reinvestSum / 100) * interestRates(reinvestSum);
        const netProfit = isReinvest
          ? reinvestSum -
            Number(investment) -
            (reinvestSum - Number(investment)) * fee
          : profitPerDay * days - profitPerDay * days * fee;

        if (Number(investment) > netProfit) {
          days++;
        } else {
          return days;
        }
      }
    }
  }
};

export const calculateReinvestProfit = (investment, period, feeState) => {
  let reinvestSum = Number(investment);
  if (!!reinvestSum) {
    let profit = 0;
    let profitSum = 0;
    for (let days = 1; days <= period; days++) {
      profit = profitSum;
      profit =
        (reinvestSum + profit) * (interestRates(reinvestSum + profit) / 100);

      // if last day - don't charge a fee
      if (Number(days) !== Number(period))
        profit -= profit * (reinvestFee / 100);

      profitSum += profit;
      /* end */
    }

    return feeState
      ? Number(profitSum) - (Number(profitSum) / 100) * investmentFee
      : Number(profitSum);
  } else {
    return 0;
  }
};

/* Form data validator */
export const formValidator = {
  checkName: function (value, t) {
    // if (!(value?.length > 1 && value?.length < 30)) {
    if (
      value?.length &&
      !(
        value?.length > 1 &&
        value?.length < 30 &&
        /^[a-zA-Zа-яА-Я\s]+$/.test(value)
      )
    ) {
      return {
        status: false,
        response: "invalid-name",
        description: t && t("formsValidatorErrors.invalidName"),
      };
    }
    return { status: true };
  },
  checkEmail: function (value, t) {
    if(!value) return { status: true }; // accept empty value
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
      return {
        status: false,
        response: "invalid-email",
        description: t && t("formsValidatorErrors.invalidEmail"),
      };
    }
    return { status: true };
  },
  checkPassword: function (value, t) {
    if (!(value?.length >= 8 && value?.length <= 16)) {
      return {
        status: false,
        response: "invalid-password-length",
        description: t && t("formsValidatorErrors.invalidPasswordLength"),
      };
    }
    return { status: true };
  },
  checkMatchValues: function (x, y, t) {
    if (x !== y) {
      return {
        status: false,
        response: "invalid-values-isn't match",
        description: t && t("formsValidatorErrors.invalidValuesIsntMatch"),
      };
    }
    return { status: true };
  },
  checkPhoneNumber: function (value, t) {
    // if (value && !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(value)) {
    if (
      value &&
      !/^\+?\d{1,3}[-\s]?\d{3,}[-\s]?\d{3,}[-\s]?\d{3,}$/.test(value)
    ) {
      return {
        status: false,
        response: "invalid-phone-number",
        description: t && t("formsValidatorErrors.invalidPhoneNumber"),
      };
    }
    return { status: true };
  },
  checkContact: function (value, type, t) {
    const dataType = { 0: "nickname", 1: "phone" }[type];

    if (!dataType) {
      return {
        status: false,
        response: "contact-field-cant-recognized-type",
        description:
          t && t("formsValidatorErrors.contactFieldCantRecognizedType"),
      };
    }

    if (!value) {
      return { status: true }; // accept empty contact
      return {
        status: false,
        response: "contact-field-is-empty",
        description: t && t("formsValidatorErrors.contactFieldIsEmpty"),
      };
    }

    if (dataType === "phone" && !/^\+\d{10,15}$/.test(value)) {
      return {
        status: false,
        response: "invalid-phone-number",
        description: t && t("formsValidatorErrors.invalidPhoneNumber"),
      };
    }

    if (
      dataType === "nickname" &&
      !/^[a-zA-Z][a-zA-Z0-9_]{4,31}$/.test(value)
    ) {
      return {
        status: false,
        response: "invalid-nickname",
        description: t && t("formsValidatorErrors.invalidNickname"),
      };
    }
    return { status: true };
  },
  checkReferral: function (value, t) {
    if(!value) return { status: true }; // accept empty value
    if (
      (value && !/^0x[a-zA-Z0-9]*$/.test(value)) ||
      (value && value?.length !== 42)
    ) {
      return {
        status: false,
        response: "invalid-referral-address",
        description: t && t("formsValidatorErrors.invalidReferralAddress"),
      };
    }
    return { status: true };
  },
};

/* Interest rate converter */
export const parseInterestRate = {
  coefficient: function (interestRate) {
    if (interestRate) {
      return (
        (parseFloat(interestRate) * 100) / 1000000000000000000000000000 / 100
      );
    }
  },
  percent: function (interestRate) {
    if (interestRate) {
      return parseFloat(
        (
          (parseFloat(interestRate) * 100) /
          1000000000000000000000000000
        ).toFixed(2)
      );
    }
  },
};

/* use a function for the exact format desired... */
export const ISODateString = (d) => {
  return d < 10 ? "0" + d : d;
};

/* Group object by value */
export const groupObjByKey = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
/* Group array by value */
export const groupArrByKey = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, []);
};

/* Recognize an error by number */
export const getErrorNumber = (err) => {
  // Perform the error check separately
  const isError001 = err.includes("User denied transaction signature");
  const isError002 = err.includes(
    "Arithmic operation resulted in underflow or overflow"
  );
  const isError003 = err.includes(
    'The contract function "withdraw" reverted with the following reason: left'
  );
  const isError004 = err.includes('Chain mismatch: Expected "BNB Smart Chain"');
  const isError005 = err.includes(
    'An error occurred when attempting to switch chain"'
  );

  // If-else statements to determine the error code
  if (isError001) {
    return "001";
  } else if (isError002) {
    return "002";
  } else if (isError003) {
    return "003";
  } else if (isError004) {
    return "004";
  } else if (isError005) {
    return "005";
  } else {
    return "000"; // Default value
  }
};