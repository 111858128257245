import { useState, useEffect } from 'react';

export const useQueryParams = () => {
  const [query, setQuery] = useState(new URLSearchParams(window.location.search));

  useEffect(() => {
    const handleLocationChange = () => {
      setQuery(new URLSearchParams(window.location.search));
    };

    window.addEventListener('popstate', handleLocationChange);

    return () => {
      window.removeEventListener('popstate', handleLocationChange);
    };
  }, []);

  return query;
};
