import { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import classNames from 'classnames'
import styles from "./BonusTimer.module.sass"

import useCountdownTimer from '../../hooks/useCountdownTimer'

import { Placeholder } from './ui/Placeholder/Placeholder'

const digitWrap = (value) => {
  return value?.split("").map((item, i) => {
    return <span key={`time-${i}`}>{item}</span>;
  });
};

const BonusTimer = ({
  endSeconds,
  onEnd,
  isPaused = false,
  seconds,
  className,
}) => {
  const { t } = useTranslation();

  const { hours, minutes, seconds: sec } = useCountdownTimer({
    endSeconds,
    seconds,
    isPaused,
  })

  useEffect(() => {
    if (hours === '00' && minutes === '00' && sec === '00' && endSeconds > 0) {
      onEnd?.()
    }
  }, [sec, minutes, hours, endSeconds])

  return endSeconds ? (
    <div className={classNames(styles.showCounter, className)}>
      <div className={classNames(styles.item, styles.hours)}>
          <div className={styles.digits}>{digitWrap(hours)}</div>
          <div className={styles.label}>{t("hours")}</div>
        </div>

        <div className={classNames(styles.item, styles.minutes)}>
          <div className={styles.digits}>{digitWrap(minutes)}</div>
          <div className={styles.label}>{t("minutes")}</div>
        </div>

        <div className={classNames(styles.item, styles.seconds)}>
          <div className={styles.digits}>{digitWrap(sec)}</div>
          <div className={styles.label}>{t("seconds")}</div>
        </div>
    </div>
  ) : <Placeholder />
}

export default BonusTimer
