import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import s from "./Dropdown.module.sass";
import cn from "classnames";

import { DropdownArrow } from "../../assets/icons";

const Dropdown = ({
  list,
  defaultActive,
  classNames,
  callbackState,
  onlyIcons,
  onlyValues,
}) => {
  const { t } = useTranslation();

  const [activeItem, setActiveItem] = useState(
    defaultActive ? defaultActive : 0
  );
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    callbackState && callbackState(activeItem);
  }, [activeItem]);

  useEffect(() => setActiveItem(defaultActive), [defaultActive]);

  return (
    <div className={cn(s.dropdown, classNames, { [s.opened]: isOpened })}>
      <div className={s.dropdown__label} onClick={() => setIsOpened(!isOpened)}>
        {onlyIcons && list[activeItem].icon && (
          <span className={s.icon}>
            <img src={list[activeItem].icon} />
          </span>
        )}
        {onlyValues && list[activeItem].icon && (
          <span className={s.value}>
            <img src={list[activeItem].icon} />
          </span>
        )}
        {!onlyIcons && !onlyValues && (
          <>
            {list[activeItem]?.icon && (
              <span className={s.icon}>
                <img src={list[activeItem].icon} />
              </span>
            )}{" "}
            {list[activeItem]?.value && (
              <span className={s.value}>{list[activeItem].value}</span>
            )}
          </>
        )}

        <img src={DropdownArrow} alt="arrow" className={s.arrowIcon} />
      </div>
      <div className={s.dropdown__listWrapper} hidden={!isOpened}>
        <div className={s.list__inner}>
          <ul className={s.list}>
            {list &&
              list.map((el, i) => {
                return (
                  <li
                    key={`list-item-${i}`}
                    className={s.list__item}
                    onClick={() => {
                      setActiveItem(i);
                      setIsOpened(!isOpened);
                    }}
                  >
                    {onlyIcons && (
                      <span className={s.icon}>
                        <img src={el.icon} />
                      </span>
                    )}
                    {onlyValues && (
                      <span className={s.value}>
                        <img src={el.icon} />
                      </span>
                    )}
                    {!onlyIcons && !onlyValues && (
                      <>
                        {el.icon && (
                          <span className={s.icon}>
                            <img src={el.icon} />
                          </span>
                        )}{" "}
                        {el.value && (
                          <span className={s.value}>{el.value}</span>
                        )}
                      </>
                    )}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
