import { secondsToDhms } from '../../tools/date-utils'
import { DateTime } from 'luxon'
import { useEffect, useRef, useState } from 'react'

const formatCountdownTime = (endSeconds, format = 'mm:ss') => {
  // Преобразуем endSeconds в UTC
  // const endDateTimeUTC = DateTime.fromHTTP(endSeconds).toUTC();
  const endDateTimeUTC = DateTime.fromHTTP(endSeconds).toUTC();
// console.log('endDateTimeUTC', endDateTimeUTC)
  // Вычисляем разницу между текущим временем и endDateTimeUTC в секундах
  const diffInSeconds = endDateTimeUTC.diffNow('seconds').seconds;

  // // Форматируем оставшееся время
  return secondsToDhms(diffInSeconds);
};

const placeholderValues = {
  days: '00',
  hours: '00',
  minutes: '00',
  seconds: '00',
}

const useCountdownTimer = ({
  endSeconds,
  onTimeStart,
  isPaused = false,
  seconds = 1000,
  format = 'hh:mm:ss',
}) => {
  const [time, setTime] = useState(endSeconds ? formatCountdownTime(endSeconds, format) : null)
  const refInterval = useRef(null)

  useEffect(() => {
    if(!endSeconds) return 
    onTimeStart?.()
    setTime(formatCountdownTime(endSeconds, format))

    refInterval.current = setInterval(() => {
      setTime(formatCountdownTime(endSeconds, format))
    }, seconds)

    if (isPaused) {
      clearInterval(refInterval.current)
      refInterval.current = null
    }

    return () => {
      clearInterval(refInterval.current)
      refInterval.current = null
    }
  }, [endSeconds, isPaused])

  if (time && time.seconds.includes('0-')) {
    return placeholderValues
  }

  return time ? time : placeholderValues
}

export default useCountdownTimer
