import styles from "./Button.module.sass";
import classNames from "classnames";

const Button = ({
  link,
  text,
  className,
  target,
  mod,
  size,
  onClick = () => {},
  ...props
}) => {
  return (
    <a
      href={link || "#"}
      className={classNames(
        styles.button,
        className && className,
        mod ? styles[`button${mod}`] : styles.buttonDefault,
        size && styles[`button${size}`]
      )}
      target={target ? target : "_self"}
      onClick={(e) => {
        onClick();
        !link && e.preventDefault();
      }}
      {...props}
    >
      <span
        className={styles.buttonLabel}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </a>
  );
};

export default Button;
