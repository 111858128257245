import { useEffect, useMemo, useState } from "react";
import s from "./ReferralsTable.module.sass";
import cn from "classnames";
import { useWalletConnectorContext } from "../../context/contract";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { groupArrByKey, truncateString } from "../../tools/common";
import { formatEther } from "ethers";
import {
  getReferralDeposits,
  getUsersContacts,
} from "../../config/requests/requests";
import Cookies from "universal-cookie";
import OnboardingOfferModal from "../../components/Popups/OnboardingOfferModal";

import mailCircleIcon from "../../assets/icons/mail-circle.svg";
import telegramCircleIcon from "../../assets/icons/telegram-circle.svg";

export const ReferralsTable = ({
  onboardingDriver,
  setOnboardingStage,
  onboardingStage,
  ...props
}) => {
  const { t } = useTranslation();
  const cookies = new Cookies();
  const onboardingStatus = cookies.get("onboardingStatus");
  const { referralUsers } = useWalletConnectorContext();

  const [referralDeposits, setReferralDeposits] = useState(null);
  const [referralContacts, setReferralContacts] = useState(null);
  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false);

  const referralUsersList =
    referralUsers?.length > 0 ? groupArrByKey(referralUsers, "lvl") : [];

  const totalDeposit = referralDeposits
    ? Object.values(referralDeposits).reduce(
        (acc, currentValue) => acc + (currentValue ? Number(currentValue) : 0),
        0
      )
    : null;
  const totalProfit = referralUsers.reduce(
    (acc, currentValue) => acc + (currentValue.reward ? Number(currentValue.reward) : 0),
    0
  );

  const referralUsersWallets = referralUsersList.length ? referralUsersList
    .flat()
    .map((user) => user.user) : []; // get all referral wallets

  useMemo(() => {
    if(!referralUsersWallets) return
    getReferralDeposits(referralUsersWallets).then((out) =>
      setReferralDeposits(out.data)
    );
    getUsersContacts(referralUsersWallets).then(out =>{
      setReferralContacts(out.data)
    }).catch(err => console.log('ReferralsTable Component -> getUsersContacts request error', err));;
  }, []);

  useEffect(() => {
    if (
      referralUsers.length &&
      onboardingStatus &&
      onboardingStatus[2] &&
      !onboardingStatus[3]
    ) {
      setOnboardingStage(3);
      setIsOnboardingModalOpen(true);
    }
  }, [referralUsers]);

  let restoreCopyButtonTimeout;
  const successCopiedHandler = (e) => {
    clearTimeout(restoreCopyButtonTimeout);
    const copyButton = e.target.closest(`.${s.copyButton}`);;
    copyButton && copyButton.classList.add(s.copied);
    restoreCopyButtonTimeout = setTimeout(() => {
      copyButton && copyButton.classList.remove(s.copied);
    }, 2000);
  };

  return (
    <>
      <div className={s.tableWrapper} {...props}>
        <div className={s.header}>
          <div className={s.title}>
            {t("profit.referralTable.myTeam")} ({referralUsersWallets.length})
          </div>
          <div className={s.info}>
            <div className={s.item}>
              <span className={s.label}>
                {t("profit.referralTable.totalDeposit")}
              </span>
              <span className={s.value}>
                {totalDeposit &&
                  Number(formatEther(BigInt(totalDeposit))).toFixed(2)}{" "}
                BNB
              </span>
            </div>
            <div className={s.item}>
              <span className={s.label}>
                {t("profit.referralTable.totalProfit")}
              </span>
              <span className={s.value}>
                {totalProfit &&
                  Number(formatEther(totalProfit.toString())).toFixed(2)}{" "}
                BNB
              </span>
            </div>
            <div className={s.item} hidden>
              <span>{t("profit.referralTable.allTime")}</span>
            </div>
          </div>
        </div>
        <div className={s.body}>
          {referralUsersList &&
            referralUsersList.length > 0 &&
            referralUsersList.map((lvlEl, i) => {
              const totalProfitLevel = lvlEl.reduce(
                (acc, currentValue) => acc + (currentValue.reward ? Number(currentValue.reward) : 0),
                0
              );
              let totalDepositLevel = 0n;
              referralDeposits &&
                lvlEl.map((el) => {
                  if (referralDeposits[el.user.toLowerCase()])
                    totalDepositLevel += BigInt(
                      referralDeposits[el.user.toLowerCase()]
                    );
                });

              return (
                <div className={s.rowLevel} key={`referrals-users-level-${i}`}>
                  <div className={s.levelHead}>
                    <div className={s.col}>
                      {t("profit.referralTable.level")}{" "}
                      {Number(lvlEl[0].lvl) + 1}
                    </div>
                    <div className={s.col}>
                      <span className={s.label}>
                        {t("profit.referralTable.network")}
                      </span>{" "}
                      <span className={s.value}>{lvlEl.length}</span>
                    </div>
                    <div className={s.col}>
                      <span className={s.label}>
                        {t("profit.referralTable.totalDeposit")}
                      </span>{" "}
                      <span className={s.value}>
                        {Number(
                          formatEther(totalDepositLevel.toString())
                        ).toFixed(2)}{" "}
                        BNB
                      </span>
                    </div>
                    <div className={s.col}>
                      <span className={s.label}>
                        {t("profit.referralTable.totalProfit")}
                      </span>{" "}
                      <span className={s.value}>
                        {Number(
                          formatEther(totalProfitLevel.toString())
                        ).toFixed(2)}{" "}
                        BNB
                      </span>
                    </div>
                  </div>
                  <div className={s.levelBody}>
                    <div className={s.referralHead}>
                      <div className={s.col}>
                        {t("profit.referralTable.referral.head.walletAddress")}
                      </div>
                      <div className={s.col}>
                        {t("profit.referralTable.referral.head.deposit")}
                      </div>
                      <div className={s.col}>
                        {t("profit.referralTable.referral.head.reward")}
                      </div>
                      <div className={s.col}>
                        {t("profit.referralTable.referral.head.contacts")}
                      </div>
                    </div>
                    <div className={s.rowReferrals}>
                      {lvlEl &&
                        lvlEl.map((el, i) => {
                          return (
                            <div
                              id="onboaring__referralInfo"
                              className={s.referral}
                              key={`referrals-user-info-${i}`}
                            >
                              <div className={cn(s.col, s.walletAddress)}>
                                <div className={s.title}>
                                  {t(
                                    "profit.referralTable.referral.head.walletAddress"
                                  )}
                                </div>
                                <CopyToClipboard text={el.user}>
                                  <>
                                    <span className={s.shortAddress}>{truncateString(el.user, 12)}</span>
                                    <span onClick={successCopiedHandler} className={s.copyButton}>
                                      <svg className={s.copyIcon} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.2" d="M16.875 3.125V13.125H13.125V6.875H6.875V3.125H16.875Z" /><path d="M16.875 2.5H6.875C6.70924 2.5 6.55027 2.56585 6.43306 2.68306C6.31585 2.80027 6.25 2.95924 6.25 3.125V6.25H3.125C2.95924 6.25 2.80027 6.31585 2.68306 6.43306C2.56585 6.55027 2.5 6.70924 2.5 6.875V16.875C2.5 17.0408 2.56585 17.1997 2.68306 17.3169C2.80027 17.4342 2.95924 17.5 3.125 17.5H13.125C13.2908 17.5 13.4497 17.4342 13.5669 17.3169C13.6842 17.1997 13.75 17.0408 13.75 16.875V13.75H16.875C17.0408 13.75 17.1997 13.6842 17.3169 13.5669C17.4342 13.4497 17.5 13.2908 17.5 13.125V3.125C17.5 2.95924 17.4342 2.80027 17.3169 2.68306C17.1997 2.56585 17.0408 2.5 16.875 2.5ZM12.5 16.25H3.75V7.5H12.5V16.25ZM16.25 12.5H13.75V6.875C13.75 6.70924 13.6842 6.55027 13.5669 6.43306C13.4497 6.31585 13.2908 6.25 13.125 6.25H7.5V3.75H16.25V12.5Z" /></svg>
                                      <svg className={s.copiedSuccessfullyIcon} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="1" y="1" width="16" height="16" rx="2" strokeWidth="1.5" /><path d="M5 8.66667L7.62623 11.2929C8.01675 11.6834 8.64992 11.6834 9.04044 11.2929L13.3333 7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg>
                                    </span>
                                  </>
                                </CopyToClipboard>
                              </div>
                              <div className={cn(s.col, s.deposit)}>
                                <div className={s.title}>
                                  {t(
                                    "profit.referralTable.referral.head.deposit"
                                  )}
                                </div>
                                {referralDeposits &&
                                  referralDeposits[el.user.toLowerCase()] &&
                                  parseFloat(
                                    Number(
                                      formatEther(
                                        referralDeposits[el.user.toLowerCase()]
                                      )
                                    ).toFixed(5)
                                  )}
                              </div>
                              <div className={cn(s.col, s.reward)}>
                                <div className={s.title}>
                                  {t(
                                    "profit.referralTable.referral.head.reward"
                                  )}
                                </div>
                                {el.reward ? parseFloat(
                                  Number(formatEther(el.reward)).toFixed(5)
                                ) : '-'}
                              </div>
                              <div
                                className={cn(s.col, s.contacts)}
                                id={
                                  Number(el.lvl) === 0 &&
                                  referralContacts &&
                                  referralContacts[el.user.toLowerCase()] &&
                                  referralContacts[el.user.toLowerCase()]?.Telegram &&
                                  referralContacts[el.user.toLowerCase()]?.Whatsapp
                                    ? "onboaring__referralInfoContacts"
                                    : ""
                                }
                              >
                                <div className={s.title}>
                                  {t(
                                    "profit.referralTable.referral.head.contacts"
                                  )}
                                </div>
                                {referralContacts && referralContacts[el.user.toLowerCase()] &&
                                  <div
                                    className={s.contacts__item}
                                  >
                                    {referralContacts[el.user.toLowerCase()]?.Whatsapp && (
                                      <a
                                        href={`https://wa.me/${referralContacts[el.user.toLowerCase()]?.Whatsapp}`}
                                      >
                                        <img
                                          src={mailCircleIcon}
                                          alt="email-contact"
                                        />
                                      </a>
                                    )}
                                    {referralContacts[el.user.toLowerCase()]?.Telegram && (
                                      <a
                                        href={`https://telegram.me/${referralContacts[el.user.toLowerCase()]?.Telegram}`}
                                      >
                                        <img
                                          src={telegramCircleIcon}
                                          alt="telegram-contact"
                                        />
                                      </a>
                                    )}
                                  </div>
                                }
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {isOnboardingModalOpen && (
        <OnboardingOfferModal
          setIsPopupOpen={setIsOnboardingModalOpen}
          stage={onboardingStage}
          onboardingDriver={onboardingDriver}
        />
      )}
    </>
  );
};
