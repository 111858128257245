import MediaQuery from 'react-responsive';

const MIN_LG_WIDTH = 1480;
const MIN_MD_WIDTH = 1240;
const MIN_DESKTOP_WIDTH = 1024;
const MIN_TABLET_WIDTH = 768;

const Desktop = (props) => (
  <MediaQuery minWidth={MIN_DESKTOP_WIDTH} {...props} />
);

const Tablet = (props) => (
  <MediaQuery
    minWidth={MIN_TABLET_WIDTH}
    maxWidth={MIN_DESKTOP_WIDTH - 1}
    {...props}
  />
);

const Mobile = (props) => (
  <MediaQuery maxWidth={MIN_TABLET_WIDTH - 1} {...props} />
);

const TabletDesktop = (props) => (
  <MediaQuery minWidth={MIN_TABLET_WIDTH} {...props} />
);

const MobileTablet = (props) => (
  <MediaQuery maxWidth={MIN_DESKTOP_WIDTH - 1} {...props} />
);

const MobileSm = (props) => (
  <MediaQuery maxWidth={MIN_MD_WIDTH - 1} {...props} />
);

const MdLg = (props) => <MediaQuery minWidth={MIN_LG_WIDTH} {...props} />;

export { Desktop, Tablet, Mobile, TabletDesktop, MobileTablet, MobileSm, MdLg };
