import { useTranslation } from "react-i18next";
import Collapsible from "react-collapsible";
import { TabletDesktop } from "../../components/Responsive";
import classNames from "classnames";
import styles from "./CollapsePanel.module.sass";

const CollapsePanel = ({
  icon,
  mod,
  title,
  text,
  id,
  setOpenState,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.section, mod && styles[`section${mod}`])}>
      <Collapsible
        className={styles.panel}
        openedClassName={styles.panelOpen}
        triggerClassName={styles.title}
        triggerOpenedClassName={styles.title}
        trigger={
          <>
            <div className={styles.titleWrap}>
              {icon && (
                <div className={styles.titleImage}>
                  <img
                    className={styles.titleImageI}
                    src={icon}
                    width={86}
                    height={86}
                    alt={title}
                    loading="lazy"
                  />
                </div>
              )}
              <div className={styles.titleContent}>
                <div
                  className={styles.titleLabel}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
                <TabletDesktop>
                  <div
                    className={styles.titleText}
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                </TabletDesktop>
              </div>
            </div>
            <span className={styles.titleIcon}>
              <span className={styles.titleOpen}>{t("show")}</span>
              <span className={styles.titleClose}>{t("hide")}</span>
              <svg
                className={styles.titleIconI}
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 2L6 6.5L10.5 2"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="square"
                />
              </svg>
            </span>
          </>
        }
        transitionTime={200}
        contentElementId={`collapsible-content-${id}`}
        triggerElementProps={{
          id: `collapsible-trigger-${id}`,
        }}
        onOpening={() => setOpenState && setOpenState(true)}
        onClosing={() => setOpenState && setOpenState(false)}
      >
        <div className={styles.drop}>{children}</div>
      </Collapsible>
    </div>
  );
};

export default CollapsePanel;
