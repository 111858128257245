export const IS_DEV_SERVER = process.env.IS_DEV_SERVER

/* Social Links */
export const socialLinks = {
  bscscan: "https://bscscan.com/address/0xa04940a8ae6e04720db703a0d34fce20970eb052",
  facebook: "https://www.facebook.com/antidoteoffcl",
  instagram: "https://www.instagram.com/antidote_offcl",
  twitter: "https://twitter.com/Antidote_Offcl",
  youtube: "https://www.youtube.com/@Antidote_Official",
  telegram: "https://t.me/+FLAkKOsvfXplZmU1",
};

/* Main domain */
export const mainDomain = "iamantidote.com";

/* Link to telegram Antidote official */
export const telegramSupportLink = "https://t.me/Antidote_Officiall";

/* Web3 project id (walletconnect) */
export const web3ProjectId = "ded634c7b22648363d2145f2f81de45d";

/* Binance Smart Chain API key */
export const bscApiKey = "SBWPI8XBBW4R12B119UZH69B5BWJZY183E";

/* Contract address */
export const tokenAddress = "0xa04940a8ae6e04720db703a0d34fce20970eb052";

/* Default Gas Fee */
export const GASFEE = 200_000;

/* Firebase CloudMasseging config */
export const firebaseConfig = {
  apiKey: "AIzaSyAY2aXMd2OM5YI0ANjv_LREs-LxNtyAaQ0",
  authDomain: "antidot-team-notification-svc.firebaseapp.com",
  projectId: "antidot-team-notification-svc",
  storageBucket: "antidot-team-notification-svc.appspot.com",
  messagingSenderId: "788090481018",
  appId: "1:788090481018:web:7002152fdda55dd1d30ec3",
  measurementId: "G-BWGBN6VR37"
};

/* Taxes */
export const investmentFee = 5; //%
export const reinvestFee = 3; //%

/* Fetch domains */
export const readerSvcDomain = "https://reader-svc.iamantidote.com";
export const arbitrageBot = "https://arbitrage-bot.iamantidote.com";
export const devRequestsDomain = "https://user.iamantidote.com";

/* Contact info */
export const corpEmail = "support@antidt.com";

/* Bug report domain */
export const reportLink = "https://redirex-tech.pp.ua/error-request.php";
