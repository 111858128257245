import styles from "./VideoPopup.module.sass";
import classNames from "classnames";

export const VideoPopup = ({ videoSource, setIsVideoModalOpen }) => {
  return (
    <div className={styles.popupWrapper}>
      <div
        className={styles.overlay}
        onClick={() => setIsVideoModalOpen(false)}
      />
      <div className={classNames(styles.popup, "fade-show")}>
        <div
          className={styles.closeBtn}
          onClick={() => setIsVideoModalOpen(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g>
              <path
                d="M18 6L6 18"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 6L18 18"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        </div>
        <div className={styles.inner}>
          <video className={styles.videoFrame} controls autoPlay>
            <source src={videoSource} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};
