import { useId } from "react";
import Collapsible from "react-collapsible";
import { useTranslation } from "react-i18next";
import styles from "./CollapsedList.module.sass";
import { TabletDesktop } from "../../components/Responsive";

const CollapsedList = ({ data }) => {
	const id = useId();
	const { t } = useTranslation();

	return (
		<div className={styles.collapsedList}>
			{data.map((item, index) => (
				<div key={`${index}-collapsed-list`} className={styles.item}>
					<div className={styles.wrapper}>
						<Collapsible
							className={styles.panel}
							openedClassName={styles.panelOpen}
							triggerClassName={styles.title}
							triggerOpenedClassName={styles.title}
							trigger={
								<>
									<span className={styles.titleLabel} dangerouslySetInnerHTML={{ __html: item.title }} />
									<span className={styles.titleIcon}>
										<TabletDesktop>
											<span className={styles.titleOpen}>{t("faq.openLabel")}</span>
											<span className={styles.titleClose}>{t("faq.closeLabel")}</span>
										</TabletDesktop>
										<svg className={styles.titleIconI} width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M1.5 2L6 6.5L10.5 2" stroke="black" strokeWidth="2" strokeLinecap="square"/>
										</svg>
									</span>
								</>
							}
							transitionTime={200}
							contentElementId={`collapsible-content-${id}`}
							triggerElementProps={{
								id: `collapsible-trigger-${id}`,
							}}
						>
							{item.text && (
								<div
									className={styles.drop}
									dangerouslySetInnerHTML={{ __html: typeof item.text == 'string' ? item.text : item.text.join('') }}
								/>
							)}
						</Collapsible>
					</div>
				</div>
			))}
		</div>
	);
}

export default CollapsedList;
