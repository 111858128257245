import { useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import styles from "../LangSwitcher.module.sass";

import * as flagIcons from "../../../assets/icons/flags";
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/arrow-up-chevron.svg';

const LangMultiSwitcher = () => {
  const { i18n } = useTranslation();

  const [isOpenedDrop, setIsOpenedDrop] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState(i18n?.language ? i18n.language : null);

  const langs = [
    {
      label: "En",
      icon: flagIcons["en"],
      locale: "en",
    },
    {
      label: "Cn",
      icon: flagIcons["cn"],
      locale: "cn",
      soon: true,
    },
    {
      label: "Es",
      icon: flagIcons["es"],
      locale: "es",
      soon: true,
    },
    {
      label: "Ae",
      icon: flagIcons["ar"],
      locale: "ar",
      soon: true,
    },
    {
      label: "Pt",
      icon: flagIcons["pt"],
      locale: "pt",
      soon: true,
    },
    {
      label: "Fr",
      icon: flagIcons["fr"],
      locale: "fr",
      soon: true,
    },
    {
      label: "De",
      icon: flagIcons["de"],
      locale: "de",
      soon: true,
    },
    {
      label: "Ru",
      icon: flagIcons["ru"],
      locale: "ru",
    },
    {
      label: "Jp",
      icon: flagIcons["jp"],
      locale: "jp",
      soon: true,
    },
    {
      label: "Kp",
      icon: flagIcons["kp"],
      locale: "kp",
      soon: true,
    },
  ];

  useEffect(() => setActiveLanguage(i18n.language), [i18n.language]);

  const handleChangeLocale = (locale) => {
    i18n.changeLanguage(locale);
    // router.push('/', '/', { locale: locale })
    // router.push({ pathname, query }, asPath, { locale: locale });
    setActiveLanguage(locale);
    setIsOpenedDrop(!isOpenedDrop);
  };

  const activeLanguageObject = langs.find(el => el.locale === activeLanguage) ?? langs[1]
  return (
    <div className={classNames(styles.langMultiSwitcher, {[styles[`active-${activeLanguage}`]]: activeLanguage})}>
      <div className={classNames(styles.activeLanguage, {[styles.opened]: isOpenedDrop} )} onClick={() => setIsOpenedDrop(!isOpenedDrop)}>
        <div className={styles.langMultiSwitcher__flagIcon}><img src={activeLanguageObject.icon} className={styles.langMultiSwitcher__icon} /></div>
        <div className={styles.langMultiSwitcher__label}>{activeLanguageObject.label}</div>
        <ArrowUpIcon className={styles.langMultiSwitcher__arrowIcon} />
      </div>
      {isOpenedDrop && <ul className={styles.langMultiSwitcher__languageList}>
        {langs.map((item) => (
          <li
            key={item.label}
            className={classNames(styles.langMultiSwitcher__langItem, {[styles.active]: activeLanguage === item.locale, [styles.soon]: item.soon})}
            onClick={() => !item.soon && handleChangeLocale(item.locale)}
          >
            <img src={item.icon} className={styles.langMultiSwitcher__flagIcon} />
            <span className={styles.langMultiSwitcher__langItemLabel}>{item.label}</span>
            {item.soon && <span className={styles.langMultiSwitcher__soonLabel}>soon</span>}
          </li>
        ))}
      </ul>}
    </div>
  );
};

export default LangMultiSwitcher;
