import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import SwitchButton from "../../SwitchButton/SwitchButton";
import { investmentFee } from "../../../config/constants";
import {
  interestRates,
  calculateProfitPerDay,
  calculateProfitPeriod,
  getInvestmentPayOffDays,
  calculateReinvestProfit,
} from "../../../tools/common";
import { bnbRateRequest } from "../../../config/requests/requests";
import {
  Desktop,
  TabletDesktop,
  Mobile,
  MobileTablet,
} from "../../../components/Responsive";
import styles from "./ProfitCalculator.module.sass";

const ProfitCalculator = () => {
  const { t } = useTranslation();
  const investmentValueRef = useRef(null);
  const [bnbRate, setBnbRate] = useState(0);
  const [investmentValue, setInvestmentValue] = useState(0.3);
  const [investmentTextMargin, setInvestmentTextMargin] = useState(0);
  const [interestRate, setInterestRate] = useState(0.9);
  const [investmentPeriod, setInvestmentPeriod] = useState(365);
  const [profitPerDay, setProfitPerDay] = useState(0);
  const [investmentPayOffDays, setInvestmentPayOffDays] = useState(0);
  const [dailyReinvesting, setDailyReinvesting] = useState(false);
  const [isActiveFee, setIsActiveFee] = useState(true);
  const [showAddInfo, setShowAddInfo] = useState(false);

  const handleToggleAddInfo = () => {
    setShowAddInfo(!showAddInfo);
  };

  const investmentHandler = (e) => {
    let interestRate = interestRates(e.target.value);
    setInvestmentValue(e.target.value !== 0 && e.target.value);
    setInterestRate(interestRate);
    setProfitPerDay(calculateProfitPerDay(e.target.value, interestRate));
  };

  const periodHandler = (e) => setInvestmentPeriod(e.target.value);

  useEffect(() => {
    let profitPerDay = calculateProfitPerDay(investmentValue, interestRate);
    profitPerDay -= (profitPerDay / 100) * (isActiveFee ? investmentFee : 0);
    setInvestmentTextMargin(investmentValueRef.current.offsetWidth);
    setProfitPerDay(profitPerDay);
    setInvestmentPayOffDays(
      getInvestmentPayOffDays(investmentValue, isActiveFee, dailyReinvesting)
    );
  }, [investmentValue, interestRate, dailyReinvesting, isActiveFee]);

  useMemo(() => bnbRateRequest().then((out) => setBnbRate(out[0])), []);

  let periodProfit = calculateProfitPeriod(profitPerDay, investmentPeriod);

  if (dailyReinvesting) {
    periodProfit = calculateReinvestProfit(
      investmentValue,
      investmentPeriod,
      isActiveFee
    );
  }

  return (
    <div className={styles.profitCalculator}>
      <div className={styles.inner}>
        <div className={styles.col}>
          <div className={styles.fields}>
            <div className={styles.fieldGroup}>
              <label className={styles.fieldLabel} htmlFor="investment">
                {t("calculator.minimumInvestment")}
              </label>
              <div
                className={styles.inputWrapper}
                style={{
                  "--investment-text": "'BNB'",
                  "--investment-text-margin": `${investmentTextMargin}px`,
                }}
              >
                <div
                  className={styles.investmentValue}
                  ref={investmentValueRef}
                >
                  {investmentValue ? investmentValue : 0.3}
                </div>
                <input
                  className={styles.fieldInput}
                  type="text"
                  onKeyUp={investmentHandler}
                  defaultValue="0.3"
                  maxLength="4"
                  id="investment"
                  onKeyPress={(e) =>
                    !/^[0-9]*(\.|,)?[0-9]*$/g.test(e.key) && e.preventDefault()
                  }
                />
                <div className={styles.subValue}>
                  {!!Number(investmentValue)
                    ? `$${Number(investmentValue * bnbRate).toFixed(2)}`
                    : "-"}
                </div>
              </div>
            </div>
            <div className={styles.fieldGroup}>
              <label className={styles.fieldLabel} htmlFor="period">
                {t("calculator.period")}
              </label>
              <div className={styles.inputWrapper}>
                <input
                  className={styles.fieldInput}
                  type="text"
                  onKeyUp={periodHandler}
                  defaultValue="365"
                  maxLength="4"
                  id="period"
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                />
                {/* <div className={styles.subValue}>{t("calculator.Days")}</div> */}
              </div>
            </div>
          </div>
          <div className={styles.fieldSeparator}>
            <MobileTablet>
              <svg
                className={styles.fieldSeparatorIcon}
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M24 2L13 13L2 2" stroke="#DBFF00" strokeWidth="4" />
                <path
                  opacity="0.5"
                  d="M24 12L13 23L2 12"
                  stroke="#DBFF00"
                  strokeWidth="4"
                />
              </svg>
            </MobileTablet>
            {investmentPayOffDays && (
              <div className={styles.fieldInfo}>
                <svg
                  className={styles.fieldInfoIcon}
                  width="39"
                  height="34"
                  viewBox="0 0 39 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="17" cy="17" r="17" fill="#DBFF00" />
                  <path
                    d="M32.5 10C34 13 37 13 38.5 10V24C37.5 21.5 33.5 21.5 32.5 24V10Z"
                    fill="#DBFF00"
                  />
                  <circle cx="17" cy="17" r="10" fill="#252526" />
                  <path
                    d="M16.3298 12.2002H18.0098L17.9538 19.1582H16.3858L16.3298 12.2002ZM17.1698 22.1402C16.5678 22.1402 16.0918 21.6642 16.0918 21.0762C16.0918 20.4742 16.5678 20.0122 17.1698 20.0122C17.7858 20.0122 18.2478 20.4742 18.2478 21.0762C18.2478 21.6642 17.7858 22.1402 17.1698 22.1402Z"
                    fill="#DBFF00"
                  />
                </svg>
                <div className={styles.fieldInfoLabel}>
                  <sup>*</sup>
                  {t("calculator.willPayOffIn")} {investmentPayOffDays}-
                  {t("calculator.days")}
                </div>
              </div>
            )}
            <MobileTablet>
              <svg
                className={styles.fieldSeparatorIcon}
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M24 2L13 13L2 2" stroke="#DBFF00" strokeWidth="4" />
                <path
                  opacity="0.5"
                  d="M24 12L13 23L2 12"
                  stroke="#DBFF00"
                  strokeWidth="4"
                />
              </svg>
            </MobileTablet>
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.calculatorInfo}>
            <Desktop>
              <svg
                className={styles.calculatorInfoIcon}
                width="36"
                height="26"
                viewBox="0 0 36 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 2L12.5 13L1.5 24"
                  stroke="#DBFF00"
                  strokeWidth="4"
                />
                <path
                  opacity="0.5"
                  d="M11.5 2L22.5 13L11.5 24"
                  stroke="#DBFF00"
                  strokeWidth="4"
                />
                <path
                  opacity="0.2"
                  d="M21.5 2L32.5 13L21.5 24"
                  stroke="#DBFF00"
                  strokeWidth="4"
                />
              </svg>
            </Desktop>
            <div className={styles.calculatorInfoCol}>
              <div className={styles.calculatorInfoTitle}>
                {t("calculator.perDay")}
              </div>
              <div className={styles.calculatorInfoValue}>
                {interestRate ? interestRate : "-"}
                <span
                  className={classNames(
                    styles.small,
                    !interestRate && "hidden"
                  )}
                >
                  {" "}
                  %
                </span>
              </div>
              {!!profitPerDay && (
                <div className={styles.calculatorInfoSum}>
                  {`${Number(profitPerDay).toFixed(5)} BNB / $${Number(
                    profitPerDay * bnbRate
                  ).toFixed(2)}`}
                </div>
              )}
            </div>
            <div className={styles.calculatorInfoCol}>
              <div className={styles.calculatorInfoTitle}>
                {t("calculator.yourProfit")}
              </div>
              <div className={styles.calculatorInfoValue}>
                {!!periodProfit ? Number(periodProfit).toFixed(5) : "-"}
                <span
                  className={classNames(
                    styles.small,
                    !periodProfit && "hidden"
                  )}
                >
                  {" "}
                  BNB
                </span>
              </div>
              <div className={styles.calculatorInfoSum}>
                {!!periodProfit &&
                  `$${Number(periodProfit * bnbRate).toFixed(3)}`}
              </div>
            </div>
            {/* <div className={styles.infoMessage}>
              <sup>*</sup>{t("calculator.hintText")}
            </div> */}
          </div>
          <Mobile>
            <div className={styles.addInfo}>
              <button
                className={classNames(
                  styles.addInfoToggle,
                  showAddInfo && styles.addInfoToggleActive
                )}
                type="button"
                onClick={handleToggleAddInfo}
              >
                {showAddInfo ? t("calculator.hide") : t("calculator.showMore")}
                <svg
                  className={styles.addInfoCaret}
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 2L6.17459 6.5L10.3492 2"
                    stroke="#DBFF00"
                    strokeWidth="2"
                    strokeLinecap="square"
                  />
                </svg>
              </button>
            </div>
          </Mobile>
          {showAddInfo && (
            <ul className={styles.list}>
              <li className={styles.listItem}>
                <svg
                  className={styles.listIcon}
                  width="10"
                  height="7"
                  viewBox="0 0 10 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 3L4 6L8.5 1.5"
                    stroke="#DBFF00"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className={styles.listTitle}>
                  {t("calculator.conditionsList.0", { amount: "0.3" })}
                </div>
              </li>
              <li className={styles.listItem}>
                <svg
                  className={styles.listIcon}
                  width="10"
                  height="7"
                  viewBox="0 0 10 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 3L4 6L8.5 1.5"
                    stroke="#DBFF00"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className={styles.listTitle}>
                  {t("calculator.conditionsList.1")}
                </div>
              </li>
              <li className={styles.listItem}>
                <svg
                  className={styles.listIcon}
                  width="10"
                  height="7"
                  viewBox="0 0 10 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 3L4 6L8.5 1.5"
                    stroke="#DBFF00"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className={styles.listTitle}>
                  {t("calculator.conditionsList.2")}
                </div>
              </li>
            </ul>
          )}
          <TabletDesktop>
            <div className={styles.addInfo}>
              <button
                className={classNames(
                  styles.addInfoToggle,
                  showAddInfo && styles.addInfoToggleActive
                )}
                type="button"
                onClick={handleToggleAddInfo}
              >
                {showAddInfo ? t("calculator.hide") : t("calculator.showMore")}
                <svg
                  className={styles.addInfoCaret}
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 2L6.17459 6.5L10.3492 2"
                    stroke="#DBFF00"
                    strokeWidth="2"
                    strokeLinecap="square"
                  />
                </svg>
              </button>
            </div>
          </TabletDesktop>
          <div className={styles.attention}>{t("calculator.attention")}</div>
        </div>
      </div>
      <div className={styles.actionBar}>
        <div className={styles.actionBarCol}>
          <SwitchButton
            label={t("calculator.dailyReinvesting")}
            // subLabel={t("calculator.reinvestFee")}
            // className="dailyReinvesting"
            className="switchButtonInline"
            id="daily-reinvesting"
            isChecked={dailyReinvesting}
            setState={setDailyReinvesting}
            // toolTipText={t("calculator.dailyReinvestingTooltip")}
          />
        </div>
        <div className={styles.actionBarCol}>
          <SwitchButton
            label={t("calculator.countWithFee")}
            subLabel={t("calculator.amountOfFee")}
            id="fee-for-withdrawal"
            isChecked={isActiveFee}
            setState={setIsActiveFee}
            className="switchButtonInline"
          />
        </div>
      </div>
    </div>
  );
};

export default ProfitCalculator;
