import { useEffect, useRef, useState } from "react";
import s from "./SettingsForm.module.sass";
import cn from "classnames";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { sha256 } from 'js-sha256';
import Dropdown from "../Dropdown";
import { useAccount, useSignMessage } from "wagmi";
import { useWalletConnectorContext } from "../../context/contract";
import { getUserMetaRequest, getServerTimestamp, setNewMetadata, getContract } from "../../config/requests/requests";
import { formValidator } from "../../tools/common";
import { TabletDesktop, Mobile } from "../../components/Responsive";

import {
  TelegramColored,
  WhatsappColored,
  PencilIcon,
} from "../../assets/icons";
import loaderImg from "../../assets/img/loader.gif"

const SettingsForm = ({ opened }) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const {isDeposited: isUserDeposited} = useWalletConnectorContext();
  
  const [userMetadata, setUserMetadata] = useState(null); // get data from back not ready yet for set
  const [contactTypeInput, setContactTypeInput] = useState(0);
  const [contactTypeNickname, setContactTypeNickname] = useState(0);
  const [requestTimestamp, setRequestTimestamp] = useState(null);
  const [saveRequestStatus, setSaveRequestStatus] = useState(null);

  const settingsForm = useRef(null);

  const [formErrors, setFormErrors] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    contactType: contactTypeInput,
    contact: "",
    email: "",
    inviter: "",
  });

  const handleChange = (e) => {
    setSaveRequestStatus(null)
    if(formErrors.length) setFormErrors([])
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const { signMessage, data, isLoading, isError, error } = useSignMessage({
    onSuccess(data) {
      console.log('Successfully signed', data)
      request(data)
    },
    onError(error) {
      setSaveRequestStatus(null)
      console.error("Error signing message:", error);
    },
  });

  const validator = (key, value) => {
    switch (key) {
      case "name":
        return formValidator.checkName(value, t);
      case "contact":
        return formValidator.checkContact(
          value,
          contactTypeNickname || contactTypeInput,
          t
        );
      case "email":
        return formValidator.checkEmail(value, t);
      case "inviter":
        return formValidator.checkReferral(value, t);
      default:
        return null;
    }
  };

  // const getUserData = 
  useEffect(() => {
    getUserMetaRequest(address).then(res => {
      if(!res?.data) return
      const {Inviter, Mail, Name, Telegram, Whatsapp} = res.data;
      setUserMetadata({Inviter, Mail, Name, Telegram, Whatsapp})
    })
  }, [address])

  const request = (signature) => {
    if(!requestTimestamp) return console.warn('requestTimestamp is empty');
    // make query
    try {
      setNewMetadata(
        address.toLowerCase(),
        formData.email,
        formData.name,
        formData.inviter.toLowerCase(),
        formData.contact,
        contactTypeInput,
        requestTimestamp,
        signature,
      ).then(() => setSaveRequestStatus('saved')).catch(() => setSaveRequestStatus(null))
    } catch (err) {
      console.warn('setNewMetadata error', err)
    }
  }

  const submitHandler = async (event) => {
    event.preventDefault();
    // const timestamp = Math.floor(Date.now() / 1000);

    setFormErrors([]) // reset errors

    getServerTimestamp().then(r => {
      const {data: timestamp} = r;
      setRequestTimestamp(timestamp);

      if(!address) return // refuse next step if address isn't exist

      // validate data
      if(!validator("name", formData.name).status) return setFormErrors(prev => [...prev, {name: validator("name", formData.name).description}])
      if(!validator("email", formData.email).status) return setFormErrors(prev => [...prev, {email: validator("email", formData.email).description}])
      if(!validator("contact", formData.contact).status) return setFormErrors(prev => [...prev, {contact: validator("contact", formData.contact).description}])
      if(!validator("inviter", formData.inviter).status) return setFormErrors(prev => [...prev, {inviter: validator("inviter", formData.inviter).description}])

      if( formData.inviter ) {
        getContract(formData.inviter, "stake").then((resp) => {
          if (resp) {
            const [, , , , , deposited] = resp;
            if(deposited){
              setSaveRequestStatus('loading')
              const nonce = `${address.toLowerCase()}${formData.email}${formData.name}${formData.contact}${formData.inviter.toLowerCase()}${timestamp}`
              signMessage({ message: `Sign the message to confirm the data update! Nonce:${sha256(nonce)}` });
            } else {
              toast.error(t("userSettings.errorText.refAddressDoesntHaveDeposit"));
            }
          }
        }).catch((err) => {
          toast.error("Something went wrong. Stake read error.");
          console.log("getContract: stake", err);
        });
      } else {
        setSaveRequestStatus('loading')
        const nonce = `${address.toLowerCase()}${formData.email}${formData.name}${formData.contact}${formData.inviter.toLowerCase()}${timestamp}`
        signMessage({ message: `Sign the message to confirm the data update! Nonce:${sha256(nonce)}` });
      }
    });
  };

  return (
    <div className={cn(s.settingsForm, { [s.opened]: opened })}>
      <div className={s.inner}>
        {!!formErrors.length && (
          <div className={s.error}>
            <span>
              {formErrors.map((el, i) => (
                <div key={`error-${i}`}>{Object.values(el)}</div>
              ))}
            </span>
          </div>
        )}
        <form
          method="post"
          className={s.form}
          ref={settingsForm}
          onSubmit={submitHandler}
        >
          <div className={s.fieldGroup}>
            <label className={s.label}>{t("userSettings.form.name")}</label>
            <div className={cn(s.inputWrapper, {[s.error]: formErrors.find(el => el['name'])})}>
              <input
                type="text"
                name="name"
                defaultValue={userMetadata ? userMetadata?.Name : ""}
                className={s.input}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={cn(s.fieldGroup, s.contact)}>
            <label className={s.label}>{t("userSettings.form.contact")}</label>
            <div className={cn(s.inputWrapper, {[s.error]: formErrors.find(el => el['contact'])})}>
              <Dropdown
                defaultActive={0}
                list={[
                  { icon: TelegramColored, value: "telegram" },
                  { icon: WhatsappColored, value: "whatsapp" },
                ]}
                callbackState={setContactTypeInput}
                classNames={s.messengersDropdown}
                onlyIcons
              />
              {contactTypeInput === 0 && (
                <>
                  <TabletDesktop>
                    <Dropdown
                      defaultActive={
                        userMetadata
                          ? /[a-zA-Z]/.test(userMetadata?.Telegram)
                            ? 0
                            : 1
                          : 0
                      }
                      list={[
                        { value: t("userSettings.dropdown.byNickname") },
                        { value: t("userSettings.dropdown.byPhone") },
                      ]}
                      callbackState={setContactTypeNickname}
                      classNames={s.isNicknameDropdown}
                    />
                  </TabletDesktop>
                  <Mobile>
                    <Dropdown
                      defaultActive={
                        userMetadata
                          ? /[a-zA-Z]/.test(userMetadata?.Telegram)
                            ? 0
                            : 1
                          : 0
                      }
                      list={[
                        { value: t("userSettings.dropdown.Nickname") },
                        { value: t("userSettings.dropdown.Phone") },
                      ]}
                      callbackState={setContactTypeNickname}
                      classNames={s.isNicknameDropdown}
                    />
                  </Mobile>
                </>
              )}

              <div className={s.input}>
                {contactTypeInput === 0 && contactTypeNickname === 0 && (
                  <span className={s.preText}>@</span>
                )}
                <input
                  type="text"
                  id="contact"
                  name="contact"
                  placeholder=" "
                  defaultValue={
                    userMetadata
                      ? contactTypeInput === 0
                        ? userMetadata?.Telegram
                        : userMetadata?.Whatsapp
                      : ""
                  }
                  className={s.phone}
                  onChange={handleChange}
                />
                <div className={s.customPlaceholders}>
                  <div
                    className={s.nickname}
                    hidden={contactTypeNickname || contactTypeInput === 1}
                  >
                    {t("userSettings.form.placeholders.nickname")}
                  </div>
                  <div
                    className={s.phone}
                    hidden={!contactTypeNickname && contactTypeInput === 0}
                  >
                    {t("userSettings.form.placeholders.phone")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={s.fieldGroup}>
            <label className={s.label}>{t("userSettings.form.email")}</label>
            <div className={cn(s.inputWrapper, {[s.error]: formErrors.find(el => el['email'])})}>
              <input
                type="email"
                name="email"
                defaultValue={userMetadata ? userMetadata?.Mail : ""}
                className={s.input}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={s.fieldGroup}>
            <label className={s.label}>{t("userSettings.form.inviter")}</label>
            <div className={cn(s.inputWrapper, {[s.error]: formErrors.find(el => el['inviter'])})}>
              <div className={s.input}>
                <input
                  type="text"
                  name="inviter"
                  defaultValue={userMetadata ? userMetadata?.Inviter : ""}
                  className={s.input}
                  disabled={isUserDeposited}
                  onChange={handleChange}
                />
              </div>
            </div>
            <label className={s.notify}>{t("userSettings.form.sublabels.inviterNotify")}</label>
          </div>
          <div className={s.fieldGroup}>
            <div className={cn(s.inputWrapper, s.submit)}>
              <button type="submit" className={cn(s.button, {[s.loading]: saveRequestStatus === 'loading', [s.saved]: saveRequestStatus === 'saved'})} disabled={!formData.name && !formData.contact && !formData.email && !formData.inviter}>
                {saveRequestStatus === 'loading' && <img src={loaderImg} className={s.loaderImg} />}
                {saveRequestStatus === 'saved' && <span className={s.checkIcon} />}
                <span>{t("userSettings.form.button.saveEdits")}</span>
                <span>{t("userSettings.form.button.saved")}</span>
                <img src={PencilIcon} />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SettingsForm;
