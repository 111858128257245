import styles from "./ReferralBonus.module.sass";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useNetwork } from "wagmi";
import { switchNetwork } from "@wagmi/core";
import { useTranslation } from "react-i18next";
import { bscscanTransactionsRequest } from "../../config/requests/requests";
import Button from "../../components/Button";
import { useWalletConnectorContext } from "../../context/contract";
import BonusTimer from "../../components/BonusTimer";
import { Mobile, TabletDesktop } from "../../components/Responsive";
import CollapsePanel from "../../components/CollapsePanel";
import { TransactionsHistory } from "../../components/TransactionsHistory";
import { formatEther } from "ethers";
import { tokenAddress, telegramSupportLink } from "../../config/constants";
import { floatCut } from "../../tools/common";
import { bsc } from "viem/chains";

import wrongNetworkImage from "../../assets/img/wrong-network.png";
import exitIconkImage from "../../assets/img/exit-icon.svg";
import transactionsImg from "../../assets/img/transactions-img.svg";

const ReferralBonus = () => {
  const { t, i18n } = useTranslation();
  const { chain } = useNetwork();
  const [wrongNetworkAlert, setWrongNetworkAlert] = useState(false);
  const [stakingAmount, setStakingAmount] = useState(0);
  const [transactions, setTransactions] = useState(null);
  const [transactionsStatus, setTransactionsStatus] = useState(null);
  const [transactionsOpen, setTransactionsOpen] = useState(null);
  const [timeoutDate, setTimeoutDate] = useState(null);
  // const FIXED_GAS_PRICE = 0.01;

  const {
    lastInvestTimestamp,
    update,
    address,
    balance,
    withdraw,
    minWithdrawValue,
    reinvest,
    rewards,
    investmentAmount,
    shouldUpdate,
    isLeave
  } = useWalletConnectorContext();

  const getTimeoutDate = async () => {
    const timestamp = await lastInvestTimestamp;
    const lastActionDate = new Date(Number(timestamp) * 1000);

    let timerDay = new Date();
    timerDay.setHours(lastActionDate.getHours());
    timerDay.setMinutes(lastActionDate.getMinutes());
    timerDay.setSeconds(lastActionDate.getSeconds());
  
    let plusOneDay = new Date(timerDay);
    if ((new Date()).getTime() >= timerDay.getTime()) {
      plusOneDay.setDate(plusOneDay.getDate() + 1);
    }
    setTimeoutDate(plusOneDay?.toUTCString());
  };

  const loadData = (rewards) => {
    const rewardsAmount = parseFloat(floatCut(formatEther(rewards), 5));
    setStakingAmount(rewardsAmount || 0);
  };

  useEffect(() => {
    if (address && transactionsOpen && !transactions) {
      try {
        bscscanTransactionsRequest(address).then((res) => {
          if (!res.data){
            setTransactionsStatus(t("infoSections.transactions.notFound"))
            return
          }
          const transactionsData = res.data.result.filter(
            (el) => el.to === tokenAddress
          ); // filter by transactions to our contract adress

          if( transactionsData?.length ) {
            let lastTransaction = new Date(
              transactionsData[0].timeStamp * 1000
            ).toLocaleString();
            lastTransaction = lastTransaction.split(", ");
            lastTransaction = lastTransaction[0].replace(/\./g, "/");

            setTransactions({
              lastTransactionDate: lastTransaction,
              transactionsList: transactionsData,
            });
          } else {
            setTransactionsStatus(t("infoSections.transactions.notFound"))
          }
        });
      } catch {
        (err) => {
          console.error(
            "Request to bscscan for get transactions list return error",
            err
          );
        };
      }
    }
  }, [transactionsOpen, address, i18n.language]);

  useEffect(
    () => loadData(rewards ? rewards : 0),
    [address, investmentAmount, balance, rewards, shouldUpdate]
  );

  useEffect(() => {
    if (chain) {
      setWrongNetworkAlert(chain.id !== bsc.id);
    }
  }, [chain]);

  useEffect(() => {
    if (lastInvestTimestamp) {
      getTimeoutDate();
    }
  }, [lastInvestTimestamp]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    const newValue = value.replace(/[^0-9.]/g, "");
    setStakingAmount(newValue);
  };

  const reinvestHandler = () => reinvest(stakingAmount);

  const withdrawHandler = () => withdraw(stakingAmount);

  // const getInitStakingValue = async () => {
  //   // const reward = rewards ? parseFloat((+formatEther(rewards)).toFixed(5)) : 0;
  //   // if (reward > FIXED_GAS_PRICE) {
  //   //   return parseFloat(Number(reward - FIXED_GAS_PRICE).toFixed(5));
  //   // }
  //   // return 0;
  //   return rewards ? parseFloat(floatCut(formatEther(rewards), 5)) : 0;
  // };

  const switchNetworkHandler = async () => {
    try {
      await switchNetwork({ chainId: bsc.id });
    } catch (err) {
      console.log("Switch network error", err);
    }
  };

  return (
    <section className={styles.referralBonus}>
      <div className="container">
        <div
          className={classNames(styles.inner, { [styles.single]: !address })}
        >
          {/* {isLeave && (
            <div className={styles.alertBox}>
              <div className={styles.image}>
                <img src={exitIconkImage} />
              </div>
              <div className={styles.text}>
                <p>{t("errorMessages.isLeave.0")}</p>
                <p>{t("errorMessages.isLeave.1")}</p>
              </div>
            </div>
          )} */}
          {wrongNetworkAlert && (
            <div className={styles.alertBox}>
              <div
                className={styles.closeBtn}
                onClick={() => setWrongNetworkAlert(false)}
              >
                <svg
                  width="10"
                  height="11"
                  viewBox="0 0 10 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1.5L5 5.5M9 9.5L5 5.5M5 5.5L9 1.5L1 9.5"
                    stroke="#252526"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className={styles.image}>
                <img src={wrongNetworkImage} />
              </div>
              <div className={styles.text}>
                <p>{t("investing.wrongNotify.text.0")}</p>
                <p>
                  {t("investing.wrongNotify.text.1")}{" "}
                  <span
                    className={styles.changeNetwork}
                    onClick={switchNetworkHandler}
                  >
                    {t("investing.wrongNotify.link")}
                  </span>
                </p>
              </div>
            </div>
          )}
          <div className={styles.top}>
            <h2 className={styles.title}>{t("referralBonus.title")}</h2>
            <Mobile>
              <div className={styles.timer} id="onboaring__referralTimer">
                <div className={styles.value}>
                  <BonusTimer 
                    endSeconds={timeoutDate}
                    onEnd={update}
                    className={styles.bonusTimer}
                  />
                </div>
              </div>
            </Mobile>
          </div>
          <div className={styles.wrap}>
            <TabletDesktop>
              <div className={styles.timer} id="onboaring__referralTimer">
                <div className={styles.value}>
                  <BonusTimer 
                    endSeconds={timeoutDate}
                    onEnd={update}
                    className={styles.bonusTimer}
                  />
                </div>
              </div>
            </TabletDesktop>
            <div className={styles.rewards}>
              <div className={styles.rewardsLabel}>
                {t("referralBonus.yourRewards")}
              </div>
              <div className={styles.rewardsWrap}>
                <div className={styles.rewardsSum}>
                  <input
                    type="text"
                    // defaultValue={"-"}
                    maxLength={10}
                    value={stakingAmount}
                    className={styles.rewardSumInput}
                    onChange={handleInputChange}
                  />
                  BNB
                </div>
                <div className={styles.rewardsNum}>
                  {rewards && parseFloat(floatCut(formatEther(rewards), 5))}
                </div>
              </div>
            </div>
            <div className={styles.controls}>
              <div className={styles.controlsItem}>
                <Button
                  id="onboaring__reinvestButton"
                  className={styles.control}
                  text={t("referralBonus.reinvestButtonLabel")}
                  mod="LightYellow"
                  size="Md"
                  disabled={
                    stakingAmount <= 0 ||
                    !address ||
                    stakingAmount > formatEther(rewards)
                  }
                  onClick={reinvestHandler}
                />
                <div className={styles.commission}>
                  3% {t("referralBonus.commission")}
                </div>
              </div>
              <div className={styles.controlsItem}>
                <Button
                  id="onboaring__withdrawButton"
                  className={styles.control}
                  text={t("referralBonus.withdrawButtonLabel")}
                  mod="Dark"
                  size="Md"
                  onClick={withdrawHandler}
                  disabled={
                    stakingAmount <= 0 ||
                    !address ||
                    stakingAmount > formatEther(rewards) ||
                    stakingAmount <
                      parseFloat(formatEther(minWithdrawValue?.toString())) ||
                    isLeave
                  }
                />
              </div>
              <div className={styles.controlsItem}>
                <Button
                  className={styles.control}
                  text={t("referralBonus.leaveButtonLabel")}
                  mod="BorderedWhite"
                  link={telegramSupportLink}
                  size="Md"
                  target="_blank"
                  disabled={!address}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {address && (
        <div
          className={styles.transactionsHistoryWrapper}
          id="onboaring__transactionHistory"
        >
          <CollapsePanel
            id="transactions-history"
            mod="Transactions"
            icon={transactionsImg}
            title={t("infoSections.transactions.title", {
              length:
                transactions && `(${transactions.transactionsList.length})`,
            })}
            text={
              transactions &&
              t("infoSections.transactions.text", {
                date: transactions.lastTransactionDate,
              })
            }
            setOpenState={setTransactionsOpen}
          >
            {transactions ? (
              <TransactionsHistory data={transactions.transactionsList} />
            ) : (
              <div className={styles.alert}>
                {transactionsStatus ? transactionsStatus : t("infoSections.transactions.loading")}
              </div>
            )}
          </CollapsePanel>
        </div>
      )}
    </section>
  );
};

export default ReferralBonus;
