import styles from "./Profit.module.sass";
import { ReferralsTable } from "../../components/ReferralsTable";
import { useWalletConnectorContext } from "../../context/contract";
import ReferralInfo from "./ui/ReferralInfo";

const Profit = ({ onboardingDriver, setOnboardingStage, onboardingStage }) => {
  // const { t, ready } = useTranslation();
  const { referralUsers } = useWalletConnectorContext();

  return (
    referralUsers?.length > 0 && (
      <div className={styles.profit}>
        <div className="container">
          <div className={styles.inner}>
            <section className={styles.total}>
              <div className={styles.row}>
                <ReferralInfo className={styles.referralInfo} />
                <ReferralsTable
                  className={styles.referralsTable}
                  onboardingDriver={onboardingDriver}
                  setOnboardingStage={setOnboardingStage}
                  onboardingStage={onboardingStage}
                />
              </div>
            </section>
          </div>
        </div>
      </div>
    )
  );
};

export default Profit;
