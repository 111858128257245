import { useTranslation } from "react-i18next";
import ProfitCalculator from "../../components/ProfitCalculator/v2";
import { Downloads, FAQ } from "../../sections";
import CollapsePanel from "../../components/CollapsePanel";
import styles from "./InfoSections.module.sass";

import calcImg from "../../assets/img/calc-img.png"
import bookImg from "../../assets/img/book-img.png"
import helpImg from "../../assets/img/help-img.png"

const InfoSections = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.infoSections}>
      <div className="container">
        <CollapsePanel
          id="calculate"
          mod="Calculate"
          icon={calcImg}
          title={t("infoSections.calculate.title")}
          text={t("infoSections.calculate.text")}
        >
          <ProfitCalculator />
        </CollapsePanel>
        <CollapsePanel
          id="downloads"
          mod="Downloads"
          icon={bookImg}
          title={t("infoSections.downloads.title")}
          text={t("infoSections.downloads.text")}
        >
          <Downloads />
        </CollapsePanel>
        <CollapsePanel
          id="help"
          mod="Help"
          icon={helpImg}
          title={t("infoSections.help.title")}
          text={t("infoSections.help.text")}
        >
          <FAQ />
        </CollapsePanel>
      </div>
    </section>
  );
};

export default InfoSections;
