import { useTranslation } from "react-i18next";
import styles from "./referralInfo.module.sass";
import { useWalletConnectorContext } from "../../../../context/contract";
import Button from "../../../../components/Button";
import { formatEther } from "viem";

const ReferralInfo = (props) => {
  const { t, ready } = useTranslation();
  const { referralUsers, investmentAmount, rewards } =
    useWalletConnectorContext();

  const totalProfit = referralUsers.reduce(
    (acc, currentValue) => acc + Number(currentValue.reward),
    0
  );

  return (
    <div className={styles.referralInfo} {...props}>
      <div className={styles.referralInfo__inner}>
        <h2 className={styles.title}>{t("profit.title")}</h2>
        <div className={styles.balanceCard} id="onboaring__profitBalanceCard">
          <div className={styles.label}>{t("profit.referralInfo.title")}</div>
          <div className={styles.amount}>
            {Number(formatEther(investmentAmount)).toFixed(5)} BNB
          </div>
          <Button
            text={t("profit.referralInfo.button.label")}
            className={styles.button}
            link="#section-investing"
          />
        </div>
        <div className={styles.tile} id="onboaring__profitBonuses">
          <div className={styles.label}>
            {t("profit.referralInfo.yourBonuses")}
          </div>
          <div className={styles.amount}>
            {Number(formatEther(rewards)).toFixed(5)} BNB
          </div>
        </div>
        <div className={styles.infoGrid}>
          <div className={styles.col}>
            <div className={styles.label}>
              {t("profit.referralInfo.turnover")}
            </div>
            <div className={styles.value}>
              {Number(formatEther(totalProfit)).toFixed(5)} BNB
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.label}>{t("profit.referralInfo.team")}</div>
            <div className={styles.value}>{referralUsers.length}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.label}>
              {t("profit.referralInfo.totalProfit")}
            </div>
            <div className={styles.value}>
              {Number(formatEther(totalProfit)).toFixed(5)} BNB
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralInfo;
