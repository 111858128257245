import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import { Desktop, MobileTablet } from "../../components/Responsive";
import styles from "./Downloads.module.sass";

import downloadsImg from "../../assets/img/downloads-img.jpg"

const Downloads = () => {
  const { t, i18n } = useTranslation();

  const docsLangMap = {
    promoMaterials: {
      en: require('../../assets/docs/promo-materials.pdf'),
      ru: require('../../assets/docs/promo-materials-ru.pdf'),
    },
    refferalProgram: {
      en: require('../../assets/docs/refferal-program.pdf'),
      ru: require('../../assets/docs/refferal-program-ru.pdf'),
    },
  };

  return (
    <section className={styles.downloads}>
      <div className={styles.inner}>
        <div className={styles.content}>
          <h2 className={styles.title}>{t("downloads.title")}</h2>
          <div className={styles.text}>{t("downloads.text")}</div>
          <MobileTablet>
            <div className={styles.image}>
              <img
                className={styles.imageI}
                src={downloadsImg}
                alt={t("downloads.title")}
                loading="lazy"
                width={747}
                height={560}
              />
            </div>
          </MobileTablet>
          <div className={styles.buttons}>
            <Button
              text={t("downloads.downloadPromoBtnLabel")}
              link={docsLangMap.promoMaterials[i18n.language]}
              target="_blank"
              mod="BorderedWhite"
              className={styles.button}
            />
            {/* <Button
              text={t("downloads.downloadReferralBtnLabel")}
              link={docsLangMap.refferalProgram[i18n.language]}
              target="_blank"
              mod="BorderedWhite"
              className={styles.button}
            /> */}
          </div>
        </div>
        <Desktop>
          <div className={styles.image}>
            <img
              className={styles.imageI}
              src={downloadsImg}
              alt={t("downloads.title")}
              loading="lazy"
              width={747}
              height={560}
            />
          </div>
        </Desktop>
      </div>
    </section>
  );
};

export default Downloads;
