import { useTranslation } from "react-i18next";
import styles from "./Footer.module.sass";

import logoDark from "../../assets/img/logo-dark.svg"

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className={styles.logo}>
          <img
            className={styles.logoImage}
            src={logoDark}
            alt="Antidote"
            loading="lazy"
            width={83}
            height={45}
          />
        </div>
        <div className={styles.copyright}>
          {t("footer.copyrightLabel", { year: currentYear })}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
