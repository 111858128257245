import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useQueryParams } from "../../tools/useQueryParams";
// import Cookies from "universal-cookie";
// import { checkAuthToken } from "../../config/requests/requests";
import { useTranslation } from "react-i18next";

const ThemeSettings = () => {
  const { t } = useTranslation();
  // const cookies = new Cookies();
  const queryParams = useQueryParams();
  const testWithdraw = queryParams.get('testWithdraw');

  useEffect(() => {
    if (testWithdraw) {
      toast.success(
        <p>
          {testWithdraw} {t("notifications.successWithdraw")}
        </p>
      );
    }
  }, [testWithdraw]);

  // useEffect(() => {
  //   // Check the token every minute (adjust the interval as needed)
  //   if (document.location.hostname !== "localhost") {
  //     const currentDomain = window.location.hostname;
  //     const mainDomain = currentDomain.replace(/^[^.]+\./, "");
  //     if(!cookies.get("accessToken")) return window.location.replace(`https://${mainDomain}?auth=false`);
  //     const tokenCheckInterval = setInterval(() => {
  //       const authToken = cookies.get("accessToken");
  //       if (authToken) {
  //         checkAuthToken(authToken)
  //           .then((resp) => {
  //             console.log("check a token", resp.status);
  //             // jwt is valid
  //           })
  //           .catch((err) => {
  //             err.response.status && console.log(err.response.status);
  //             cookies.remove("accessToken", {
  //               path: "/",
  //               domain: `.${mainDomain}`,
  //             });
  //             window.location.replace(`https://${mainDomain}?auth=false`);
  //           });
  //       } else {
  //         window.location.replace(`https://${mainDomain}?auth=false`);
  //       }
  //     }, 2 * 60 * 1000);

  //     // Cleanup the interval on component unmount
  //     return () => clearInterval(tokenCheckInterval);
  //   }
  // }, []);
};
export default ThemeSettings;
