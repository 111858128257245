// Возвращает дату полюс указанные вторым параметром период
const calculateNextPayTime = (
  lastPayDate,
  period,
) => {
  const prevDate = new Date(+lastPayDate * 1000)

  const nextDate = new Date(prevDate)

  switch (period) {
    case 'month':
      nextDate.setMonth(nextDate.getDay() + 1)
      break
    case 'day':
      nextDate.setHours(nextDate.getHours() + 24)
      break
    case 'hour':
      nextDate.setHours(nextDate.getHours() + 1)
      break
    default:
      nextDate.setMonth(nextDate.getMonth() + 1)
      break
  }

  return nextDate
}

// Возвращает разницу между двумя датами в секундах
// @ts-expect-error
const dateDifferenceInSeconds = (dateInitial, dateFinal) =>
  (dateFinal - dateInitial) / 1_000

// Трансформирует время в секундах в дни/часы/минуты/сек , так же может возвращать эти параметры с текстом
function secondsToDhms(seconds, withText = false) {
  if(!seconds) return null
  seconds = Number(seconds)
  const d = formatNumberToTwoDigits(Math.floor(seconds / (3600 * 24)))
  const h = formatNumberToTwoDigits(Math.floor((seconds % (3600 * 24)) / 3600))
  const m = formatNumberToTwoDigits(Math.floor((seconds % 3600) / 60))
  const s = formatNumberToTwoDigits(Math.floor(seconds % 60))

  const dDisplay = +d > 0 ? d + (+d === 1 ? ' day' : ' days') : ''
  const hDisplay = +h > 0 ? h + (+h === 1 ? ' hour' : ' hours') : ''
  const mDisplay = +m > 0 ? m + (+m === 1 ? ' minute' : ' minutes') : ''
  const sDisplay = +s > 0 ? s + (+s === 1 ? ' second' : ' seconds') : ''

  return {
    days: withText ? dDisplay : d,
    hours: withText ? hDisplay : h,
    minutes: withText ? mDisplay : m,
    seconds: withText ? sDisplay : s,
  }
}

// Форматирует количество дней/часов/минут/секунд к двухзначному формату
const formatNumberToTwoDigits = (value) => {
  if (value < 10) {
    return `0${value}`
  }

  return `${value}`
}

export {
  calculateNextPayTime,
  dateDifferenceInSeconds,
  formatNumberToTwoDigits,
  secondsToDhms,
}
