import { useEffect } from 'react'
import { initializeApp } from "firebase/app"
import { getMessaging, getToken } from "firebase/messaging"
import Cookies from 'universal-cookie'
//// import { messaging } from "../../config/firebase"
// import { createTokenPushRequest } from '../../config/rates'
import { firebaseConfig } from '../../config/constants'

const FCM = () => {
    const cookies = new Cookies()
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    function requestPermission() {
        console.log('Requesting permission...')
        try {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                    // get token
                    getToken(messaging, { vapidKey: 'BG9PwHHC9oBSOawqBprraEIWaefa4qNbeex6q9xZN_ZNkAoAjVjS7e80755K-u7I0vSjFwcYOska5ZTifrrQMV8' }).then((currentToken) => {
                        if (currentToken) {
                            console.log('FBS CM token', currentToken)
                            // createTokenPushRequest(currentToken)
                            cookies.set('push-notification-token', currentToken, { path: '/' })
                        } else {
                            // Show permission request UI
                            console.log('No registration token available. Request permission to generate one.');
                            // ...
                        }
                    }).catch((err) => {
                        console.log('An error occurred while retrieving token. ', err);
                        // ...
                    });
                } else if (permission === 'denied') {
                    console.log('You denied for the push-notifications.')
                }
            })
        } catch (error) {
            console.error('Notification error: ', error)
        }
    }

    useEffect(() => requestPermission(), [])

}

export default FCM