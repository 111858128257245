
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import styles from "../../BonusTimer.module.sass"

export const Placeholder = () => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.showCounter, styles.placeholder)}>
      <div className={classNames(styles.item, styles.hours)}>
        <div className={styles.digits}>
          <span>-</span>
          <span>-</span>
        </div>
        <div className={styles.label}>{t("hours")}</div>
      </div>
      <div className={classNames(styles.item, styles.minutes)}>
        <div className={styles.digits}>
          <span>-</span>
          <span>-</span>
        </div>
        <div className={styles.label}>{t("minutes")}</div>
      </div>
      <div className={classNames(styles.item, styles.seconds)}>
        <div className={styles.digits}>
          <span>-</span>
          <span>-</span>
        </div>
        <div className={styles.label}>{t("seconds")}</div>
      </div>
    </div>
  );
};